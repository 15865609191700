import React, { useEffect, useState } from 'react';
import './QrGen.css';

const QrGen = () => {
  const [employees, setEmployees] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const itemsPerPage = 8;

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        setLoading(true);
        const response = await fetch('https://javapaas-188839-0.cloudclusters.net/api/employees/all');
        const data = await response.json();
        setEmployees(data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching employee data:', error);
        setLoading(false);
      }
    };
    fetchEmployees();
  }, []);

  const handleQrGenerate = async (employee) => {
    try {
      const qrRequest = {
        eid: employee.eid,
        fullName: employee.fullName,
        division: employee.division,
        nic: employee.nic,
        address: employee.address,
        birthday: employee.dateOfBirth,
      };

      const qrResponse = await fetch('https://javapaas-188839-0.cloudclusters.net/api/qr/generateQRCode', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(qrRequest),
      });

      if (qrResponse.ok) {
        const qrImageBlob = await qrResponse.blob();
        const qrImageUrl = URL.createObjectURL(qrImageBlob);

        const link = document.createElement('a');
        link.href = qrImageUrl;
        link.download = `${employee.eid}_QRCode.png`;
        link.click();

        URL.revokeObjectURL(qrImageUrl);
      } else {
        console.error('Error generating QR Code:', await qrResponse.text());
      }
    } catch (error) {
      console.error('Error generating QR Code:', error);
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredEmployees = employees.filter((employee) =>
    employee.eid.toLowerCase().includes(searchTerm.toLowerCase()) ||
    employee.fullName.toLowerCase().includes(searchTerm.toLowerCase()) ||
    employee.division.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Pagination logic
  const indexOfLastEmployee = currentPage * itemsPerPage;
  const indexOfFirstEmployee = indexOfLastEmployee - itemsPerPage;
  const currentEmployees = filteredEmployees.slice(indexOfFirstEmployee, indexOfLastEmployee);

  const totalPages = Math.ceil(filteredEmployees.length / itemsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handlePrevClick = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextClick = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const getPaginationRange = () => {
    const start = Math.max(1, currentPage - 1);
    const end = Math.min(totalPages, start + 2);
    return Array.from({ length: end - start + 1 }, (_, i) => start + i);
  };

  return (
    <div className="qr-gen-container">
      <div className="header">
        <h1>QR Generate</h1>
      </div>

      <div className="comment-table-header-container">
        <input
          type="text"
          placeholder="Search by EID, Full Name, or Division"
          value={searchTerm}
          onChange={handleSearch}
          className="comment-table-search-bar"
        />
      </div>

      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          <table className="employee-table">
            <thead>
              <tr>
                <th>EID</th>
                <th>Full Name</th>
                <th>Division</th>
                <th>NIC</th>
                <th>Address</th>
                <th>Birthday</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {currentEmployees.length > 0 ? (
                currentEmployees.map((employee) => (
                  <tr key={employee.eid}>
                    <td>{employee.eid}</td>
                    <td>{employee.fullName}</td>
                    <td>{employee.division}</td>
                    <td>{employee.nic}</td>
                    <td>{employee.address}</td>
                    <td>{new Date(employee.dateOfBirth).toLocaleDateString()}</td>
                    <td>
                      <button className="qr-button" onClick={() => handleQrGenerate(employee)}>
                        Generate QR
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="7">No Data Available</td>
                </tr>
              )}
            </tbody>
          </table>

          {/* Pagination */}
          <div className="pagination">
            <button
              className={`pagination-arrow-button ${currentPage === 1 ? 'pagination-arrow-button-disabled' : ''}`}
              onClick={handlePrevClick}
              disabled={currentPage === 1}
            >
              &lt;
            </button>
            {getPaginationRange().map((pageNumber) => (
              <button
                key={pageNumber}
                className={`pagination-button ${pageNumber === currentPage ? 'pagination-button-active' : ''}`}
                onClick={() => paginate(pageNumber)}
              >
                {pageNumber}
              </button>
            ))}
            <button
              className={`pagination-arrow-button ${currentPage === totalPages ? 'pagination-arrow-button-disabled' : ''}`}
              onClick={handleNextClick}
              disabled={currentPage === totalPages}
            >
              &gt;
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default QrGen;
