import React, { useEffect, useState } from "react";
import "./Dashbord.css";
import Div from "../images/Div.png";

const AdminDashboard = ({ setActivePage }) => {
  const [counts, setCounts] = useState({
    rangiriHoldings: 0,
    strechtech: 0,
    nishuCreation: 0,
    nishuApparel: 0,
    livinco: 0,
    drugMart: 0,
    rangiriAqua: 0,
    synergyHeart: 0,
  });

  const [employees, setEmployees] = useState([]);
  const [selectedDivision, setSelectedDivision] = useState(null);
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const EMPLOYEES_PER_PAGE = 8;

  useEffect(() => {
    const fetchCounts = async () => {
      try {
        const response = await fetch(
          "https://javapaas-188839-0.cloudclusters.net/api/employees/counts-by-division"
        );
        if (!response.ok) throw new Error("Failed to fetch division counts.");
        const data = await response.json();
        setCounts({
          rangiriHoldings: data["Rangiri Holdings HeadOffice"] || 0,
          strechtech: data["Strechtech"] || 0,
          nishuCreation: data["Nishu Creation"] || 0,
          nishuApparel: data["Nishu Apparal"] || 0,
          livinco: data["Livinco"] || 0,
          drugMart: data["Drug Mart"] || 0,
          rangiriAqua: data["Rangiri Aqua Action Park"] || 0,
          synergyHeart: data["Synergy of Heart & Soul"] || 0,
        });
      } catch (error) {
        console.error("Error fetching division counts:", error);
      }
    };

    fetchCounts();
  }, []);

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const response = await fetch("https://javapaas-188839-0.cloudclusters.net/api/employees/all");
        if (!response.ok) throw new Error("Failed to fetch employees.");
        const data = await response.json();
        setEmployees(data);
      } catch (error) {
        console.error("Error fetching employees:", error);
      }
    };

    fetchEmployees();
  }, []);

  useEffect(() => {
    if (selectedDivision) {
      const filtered = employees.filter(
        (employee) =>
          employee.division === selectedDivision &&
          (employee.fullName.toLowerCase().includes(searchQuery.toLowerCase()) ||
            employee.eid.toLowerCase().includes(searchQuery.toLowerCase()) ||
            employee.nic.toLowerCase().includes(searchQuery.toLowerCase()))
      );
      setFilteredEmployees(filtered);
    } else {
      setFilteredEmployees([]);
    }
  }, [selectedDivision, employees, searchQuery]);

  const isCurrentlyInCompany = (dischargeDate) => {
    if (!dischargeDate) return true;
    const today = new Date();
    const discharge = new Date(dischargeDate);
    return discharge > today;
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    const totalPages = Math.ceil(filteredEmployees.length / EMPLOYEES_PER_PAGE);
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const paginatedEmployees = filteredEmployees.slice(
    (currentPage - 1) * EMPLOYEES_PER_PAGE,
    currentPage * EMPLOYEES_PER_PAGE
  );

  const totalPages = Math.ceil(filteredEmployees.length / EMPLOYEES_PER_PAGE);

  return (
    <div className="app">
      <div className="content">
        <div className="dashboard-container">
          <div className="top-bar">
            <h1>
              <span className="rounded-text">Hello HR Manager!</span>
            </h1>
            <h2>Welcome</h2>
            <div className="top-bar-icon">
              <img src={Div} alt="Div" className="div-icon" />
            </div>
          </div>

          <div className="divisions">
            <button
              className="division division1"
              onClick={() => setSelectedDivision("Rangiri Holdings HeadOffice")}
            >
              Rangiri Holdings HeadOffice
              <br />
              Count: {counts.rangiriHoldings}
            </button>
            <button
              className="division division2"
              onClick={() => setSelectedDivision("Strechtech")}
            >
              Stretchtec
              <br />
              Count: {counts.strechtech}
            </button>
            <button
              className="division division3"
              onClick={() => setSelectedDivision("Nishu Creation")}
            >
              Nishu Creation
              <br />
              Count: {counts.nishuCreation}
            </button>
            <button
              className="division division4"
              onClick={() => setSelectedDivision("Nishu Apparal")}
            >
              Nishu Apparal
              <br />
              Count: {counts.nishuApparel}
            </button>
            <button
              className="division division5"
              onClick={() => setSelectedDivision("Livinco")}
            >
              Livinco
              <br />
              Count: {counts.livinco}
            </button>
            <button
              className="division division6"
              onClick={() => setSelectedDivision("Drug Mart")}
            >
              Drug Mart
              <br />
              Count: {counts.drugMart}
            </button>
            <button
              className="division division7"
              onClick={() => setSelectedDivision("Rangiri Aqua Action Park")}
            >
              Rangiri Aqua Action Park
              <br />
              Count: {counts.rangiriAqua}
            </button>
            <button
              className="division division8"
              onClick={() => setSelectedDivision("Synergy of Heart & Soul")}
            >
              Synergy of Heart & Soul
              <br />
              Count: {counts.synergyHeart}
            </button>
          </div>

          {selectedDivision && (
            <div className="employee-details">
              <h3>Employees in {selectedDivision}</h3>
              <input
                type="text"
                placeholder="Search by name, EID, or NIC"
                className="search-bar"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              {paginatedEmployees.length > 0 ? (
                <>
                  <table>
                    <thead>
                      <tr>
                        <th>EID</th>
                        <th>Full Name</th>
                        <th>Division</th>
                        <th>Email</th>
                        <th>Mobile</th>
                        <th>Joining Date</th>
                        <th>Resigning Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {paginatedEmployees.map((employee) => (
                        <tr
                          key={employee.employeeId}
                          className={
                            isCurrentlyInCompany(employee.dischargeDate)
                              ? "highlight-row"
                              : "inactive-row"
                          }
                        >
                          <td>{employee.eid}</td>
                          <td>{employee.fullName}</td>
                          <td>{employee.division}</td>
                          <td>{employee.email}</td>
                          <td>{employee.mobileNumber}</td>
                          <td>{employee.startDate}</td>
                          <td>
                            {employee.dischargeDate
                              ? new Date(
                                  employee.dischargeDate
                                ).toLocaleDateString()
                              : "Currently Employed"}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  {/* Pagination */}
                  <div className="pagination">
                    <button onClick={handlePreviousPage} disabled={currentPage === 1}>
                      &lt; Previous
                    </button>
                    {[...Array(totalPages)].map((_, index) => (
                      <button
                        key={index}
                        onClick={() => handlePageChange(index + 1)}
                        className={
                          index + 1 === currentPage ? "active-page" : ""
                        }
                      >
                        {index + 1}
                      </button>
                    ))}
                    <button
                      onClick={handleNextPage}
                      disabled={currentPage === totalPages}
                    >
                      Next &gt;
                    </button>
                  </div>
                </>
              ) : (
                <p>No employees found in this division.</p>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
