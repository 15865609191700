import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Educational_details.css';

// Modal component for viewing educational details in a table format
const Modal = ({ educationDetails, closeModal }) => (
    <div className="Admin_view_edu_modal-overlay" role="dialog" aria-labelledby="modal-title" aria-modal="true">
        <div className="Admin_view_edu_modal-content">
            <h2 id="modal-title" className="Admin_view_edu_modal-title">
                EID {educationDetails[0]?.employeeId} Educational Details
            </h2>
            <table className="Admin_view_edu_education-table">
                <thead>
                    <tr>
                        {Object.keys(educationDetails[0] || {}).map((key) =>
                            key !== 'educationId' && key !== 'employeeId' ? (
                                <th key={key}>
                                    {key.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())}
                                </th>
                            ) : null
                        )}
                    </tr>
                </thead>
                <tbody>
                    {educationDetails.map((detail, index) => (
                        <tr key={index}>
                            {Object.keys(detail).map((key) =>
                                key !== 'educationId' && key !== 'employeeId' ? (
                                    <td key={key}>{detail[key]}</td>
                                ) : null
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="Admin_view_edu_modal-buttons">
                <button className="Admin_view_edu_close-button" onClick={closeModal}>
                    Close
                </button>
            </div>
        </div>
    </div>
);

// Main component to display and manage educational details
const AdminEducationalDetails = () => {
    const [educationData, setEducationData] = useState([]);
    const [uniqueEmployees, setUniqueEmployees] = useState([]);
    const [selectedEducation, setSelectedEducation] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(8); // Now displaying 8 records per page

    useEffect(() => {
        fetchEducationData();
    }, []);

    useEffect(() => {
        filterUniqueEmployees();
    }, [educationData, searchTerm]);

    // Fetch all education data from the backend
    const fetchEducationData = async () => {
        try {
            const response = await axios.get('https://javapaas-188839-0.cloudclusters.net/api/education/all');
            setEducationData(response.data);
        } catch (error) {
            console.error('Error fetching education data:', error);
        }
    };

    // Filter and group education data by unique employee IDs
    const filterUniqueEmployees = () => {
        const filtered = educationData.filter((education) =>
            education.employeeId.toLowerCase().includes(searchTerm.toLowerCase())
        );

        // Create a map to filter unique employee entries based on employeeId
        const unique = [...new Map(filtered.map((item) => [item.employeeId, item])).values()];
        setUniqueEmployees(unique);
    };

    // Open modal with educational details of the selected employee
    const handleViewClick = (employeeId) => {
        const employeeEducation = educationData.filter((ed) => ed.employeeId === employeeId);
        setSelectedEducation(employeeEducation);
        setModalOpen(true);
    };

    // Close the modal and reset selected education data
    const closeModal = () => {
        setModalOpen(false);
        setSelectedEducation([]);
    };

    // Calculate the current items for the table based on the current page
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = uniqueEmployees.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(uniqueEmployees.length / itemsPerPage);

    // Handle next and previous page clicks
    const handleNextPage = () => {
        setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
    };

    const handlePreviousPage = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    };

    // Logic to display only 3 page numbers at a time
    const getVisiblePageNumbers = () => {
        const maxVisiblePages = 3;
        const startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
        const endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

        let pageNumbers = [];
        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(i);
        }

        return pageNumbers;
    };

    return (
        <div className="Admin_view_edu_container-Hr">
            <h1 className="Admin_view_edu_header-title-main">Educational Details</h1>
            <div className="Admin_view_edu_search-add-container">
                <div className="Admin_view_edu_search-input-container">
                    <input
                        className="Admin_view_edu_search-input"
                        placeholder="Search by Employee ID..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </div>
            </div>

            <table className="Admin_view_edu_employee-table">
                <thead className="Admin_view_edu_table-header">
                    <tr>
                        <th className="Admin_view_edu_header-title">Employee ID</th>
                        <th className="Admin_view_edu_header-title">Number of Records</th>
                        <th className="Admin_view_edu_header-title">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {currentItems.map((employee, index) => (
                        <tr key={employee.employeeId} className={index % 2 === 0 ? 'Admin_view_edu_even-row' : 'Admin_view_edu_odd-row'}>
                            <td>{employee.employeeId}</td>
                            <td>{educationData.filter((ed) => ed.employeeId === employee.employeeId).length}</td>
                            <td>
                                <button className="Admin_view_edu_view-button" onClick={() => handleViewClick(employee.employeeId)}>
                                    View
                                </button>
                            </td>
                        </tr>
                    ))}
                    {currentItems.length === 0 && (
                        <tr>
                            <td colSpan="3" className="Admin_view_edu_no-data">
                                No education data available
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>

            {/* Pagination Controls */}
            <div className="Admin_view_edu_pagination">
                <button
                    className="Admin_view_edu_pagination-button"
                    onClick={handlePreviousPage}
                    disabled={currentPage === 1}
                >
                    &lt;
                </button>

                {getVisiblePageNumbers().map((pageNumber) => (
                    <button
                        key={pageNumber}
                        className={`Admin_view_edu_pagination-button ${currentPage === pageNumber ? 'active' : ''}`}
                        onClick={() => setCurrentPage(pageNumber)}
                    >
                        {pageNumber}
                    </button>
                ))}

                <button
                    className="Admin_view_edu_pagination-button"
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                >
                    &gt;
                </button>
            </div>

            {modalOpen && <Modal educationDetails={selectedEducation} closeModal={closeModal} />}
        </div>
    );
};

export default AdminEducationalDetails;
