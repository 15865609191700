import React, { useState, useEffect } from 'react';
import AddPersonalDetails from './PersonalDetails';
import AddParentsDetais from './ParentsDetais';
import EmergencyDetails from './EmergencyDetails';
import EducationalDetails from './EducationalDetails';
import AddChildrensDetails from './ChildrensDetails';
import Image from './Image';

const AddEmployee = () => {
  const [activeTab, setActiveTab] = useState('AddPersonalDetails');
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Update the window width when resizing
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const renderComponent = () => {
    switch (activeTab) {
      case 'AddPersonalDetails':
        return <AddPersonalDetails />;
      case 'AddParentsDetais':
        return <AddParentsDetais />;
      case 'EmergencyDetails':
        return <EmergencyDetails />;
      case 'EducationalDetails':
        return <EducationalDetails />;
      case 'AddChildrensDetails':
        return <AddChildrensDetails />;
      case 'Image':
        return <Image />;
      default:
        return <AddPersonalDetails />;
    }
  };

  const buttonStyle = (tabName) => ({
    padding: '12px 24px',
    background: activeTab === tabName ? '#01008A' : '#f1f1f1',
    color: activeTab === tabName ? '#fff' : '#333',
    borderRadius: '20px',
    border: '2px solid #01008A',
    cursor: 'pointer',
    boxShadow: activeTab === tabName ? '0 4px 8px rgba(1, 0, 138, 0.4)' : '0 2px 4px rgba(1, 0, 138, 0.2)',
    transform: activeTab === tabName ? 'scale(1.1)' : 'scale(1)',
    transition: 'all 0.3s ease',
    fontWeight: 'bold',
    textTransform: 'capitalize',
  });

  // Apply responsive styles based on window width
  const containerStyle = {
    padding: windowWidth <= 840 ? '20px' : '40px',
    maxWidth: windowWidth <= 740 ? '100%' : windowWidth <= 840 ? '90%' : '1100px',
    margin: 'auto',
    backgroundColor: '#ffffff',
    borderRadius: '20px',
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)',
    marginTop: windowWidth <= 840 ? '20px' : '-100vh',
    
    marginLeft: windowWidth <= 840 ? '20px' : '300px',
  };

  return (
    <div style={containerStyle}>
      <h1 style={{
        textAlign: 'center',
        color: '#333',
        fontSize: '35px',
        margin: '0 0 30px',
        fontWeight: 'bold'
      }}>
        Employee Information
      </h1>
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '30px',
        flexWrap: 'wrap'
      }}>
        <button onClick={() => setActiveTab('AddPersonalDetails')} style={buttonStyle('AddPersonalDetails')}>Personal Details</button>
        <button onClick={() => setActiveTab('EducationalDetails')} style={buttonStyle('EducationalDetails')}>Education</button>
        <button onClick={() => setActiveTab('EmergencyDetails')} style={buttonStyle('EmergencyDetails')}>Emergency</button>
        <button onClick={() => setActiveTab('AddParentsDetais')} style={buttonStyle('AddParentsDetais')}>Parent Details</button>
        <button onClick={() => setActiveTab('AddChildrensDetails')} style={buttonStyle('AddChildrensDetails')}>Children Details</button>
        <button onClick={() => setActiveTab('Image')} style={buttonStyle('Image')}>Image</button>
      </div>
      <div>
        {renderComponent()}
      </div>
    </div>
  );
};

export default AddEmployee;
