import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FaSearch } from 'react-icons/fa';
import './ChildrenDetails.css';


const ChildrenDetails = () => {
    const [data, setData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [isAlertVisible, setIsAlertVisible] = useState(false);
    const [selectedEmployeeChildren, setSelectedEmployeeChildren] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(8);
    const navigate = useNavigate();
    
    useEffect(() => {
        fetchChildrenData();
    }, []);

    const fetchChildrenData = () => {
        setLoading(true);
        axios.get('https://javapaas-188839-0.cloudclusters.net/api/children/all')
            .then(response => {
                setData(response.data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching children data:', error);
                setError('Error fetching data, please try again later.');
                setLoading(false);
            });
    };

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
        setCurrentPage(1); // Reset to the first page on search
    };

    const handleViewClick = (employeeId) => {
        const childrenOfEmployee = data.filter(child => child.employeeId === employeeId);
        setSelectedEmployeeChildren(childrenOfEmployee);
        setIsAlertVisible(true);
    };

    const handleCloseAlert = () => {
        setIsAlertVisible(false);
    };

    const filteredData = data.filter(item =>
        Object.values(item).some(value =>
            value?.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
    );

    const uniqueEmployees = [...new Map(filteredData.map(item => [item.employeeId, item])).values()];

    const handleAddChild = () => {
        navigate('/hr/AddChild');
    };

    // Pagination Logic
    const indexOfLastEmployee = currentPage * itemsPerPage;
    const indexOfFirstEmployee = indexOfLastEmployee - itemsPerPage;
    const currentItems = uniqueEmployees.slice(indexOfFirstEmployee, indexOfLastEmployee);

    // Set current employees for the current page
    
    const totalPages = Math.ceil(uniqueEmployees.length / itemsPerPage);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const handlePrevClick = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextClick = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const getPaginationRange = () => {
        const start = Math.max(1, currentPage - 1);
        const end = Math.min(totalPages, start + 2);
        return Array.from({ length: end - start + 1 }, (_, i) => start + i);
    };


    return (
        <div className="view_children_container">
            <h1 className="view_children_header-title-main">Children Details</h1>
            <div className="view_children_search-add-container">
                <div className="view_children_search-input-container">
                    <input
                        type="text"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={handleSearch}
                        className="view_children_search-input"
                    />
                </div>
            </div>

            {loading ? (
                <div className="view_children_loading">Loading...</div>
            ) : error ? (
                <div className="view_children_error-message">{error}</div>
            ) : (
                <>
                    <table className="view_children_employee-table">
                        <thead>
                            <tr className="view_children_table-subheader">
                                <th>Employee ID</th>
                                <th>Number of Children</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems.length > 0 ? (
                                currentItems.map((row, rowIndex) => (
                                    <tr
                                        key={row.employeeId}
                                        className={rowIndex % 2 === 0 ? 'view_children_even-row' : 'view_children_odd-row'}
                                    >
                                        <td>{row.employeeId}</td>
                                        <td>{filteredData.filter(child => child.employeeId === row.employeeId).length}</td>
                                        <td>
                                            <button
                                                onClick={() => handleViewClick(row.employeeId)}
                                                className="view_children_view-button"
                                            >
                                                View
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="5" className="view_children_no-data">No data found</td>
                                </tr>
                            )}
                        </tbody>
                    </table>

                    {/* Pagination */}
                    <div className="comment-table-pagination">
                        <button
                            className={`comment-table-arrow-button ${currentPage === 1 ? 'comment-table-arrow-button-disabled' : ''}`}
                            onClick={handlePrevClick}
                            disabled={currentPage === 1}
                        >
                            &lt;
                        </button>
                        {getPaginationRange().map((pageNumber) => (
                            <button
                                key={pageNumber}
                                className={`comment-table-pagination-button ${pageNumber === currentPage ? 'comment-table-pagination-button-active' : ''}`}
                                onClick={() => paginate(pageNumber)}
                            >
                                {pageNumber}
                            </button>
                        ))}
                        <button
                            className={`comment-table-arrow-button ${currentPage === totalPages ? 'comment-table-arrow-button-disabled' : ''}`}
                            onClick={handleNextClick}
                            disabled={currentPage === totalPages}
                        >
                            &gt;
                        </button>
                    </div>
                </>
            )}

            {/* Modal to display children details */}
            {isAlertVisible && selectedEmployeeChildren.length > 0 && (
                <div className={`view_children_modal-overlay ${isAlertVisible ? 'active' : ''}`}>
                    <div className={`view_children_modal-content ${isAlertVisible ? 'active' : ''}`}>
                        <h2 className="view_children_modal-title">Employee ID: {selectedEmployeeChildren[0].employeeId} Children Details</h2>
                        
                        {/* Scrollable table content */}
                        <div className="view_children_table-scrollable">
                            <table className="view_children_details-table">
                                <thead>
                                    <tr>
                                        <th>Child ID</th>
                                        <th>Child Name</th>
                                        <th>Child Age</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {selectedEmployeeChildren.map(child => (
                                        <tr key={child.childId}>
                                            <td>{child.childId}</td>
                                            <td>{child.childName}</td>
                                            <td>{child.childAge}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        
                        <div className="view_children_modal-buttons">
                            <button
                                onClick={handleCloseAlert}
                                className="view_children_close-button"
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ChildrenDetails;
