import React, { useContext, useState } from 'react';
import { FaTachometerAlt, FaUsers, FaSignOutAlt, FaUser, FaGraduationCap, FaChild, FaUserTie, FaHeartbeat, FaQrcode, FaComment, FaCommentAlt, FaCommentDots, FaCommentMedical } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import './Sidebar.css';
import { AuthContext } from '../AuthContext';

const HrSidebar = ({ activePage, setActivePage }) => {
  // Get the logout function from the AuthContext
  const { logout } = useContext(AuthContext);
  
  // Hook for programmatic navigation
  const navigate = useNavigate();
  
  // State to control the visibility of the employee submenu
  const [showEmployeeSubmenu, setShowEmployeeSubmenu] = useState(false);

  // Function to handle main menu item clicks
  const handleMenuClick = (menu) => {
    setActivePage(menu); // Set the active page state to highlight the active menu item

    // Navigate to the appropriate route based on the menu item clicked
    if (menu === 'dashboard') {
      navigate('/hr');
    } else if (menu === 'HrEmployeedetails') {
      // Toggle the visibility of the employee submenu when "Employee Details" is clicked
      setShowEmployeeSubmenu(!showEmployeeSubmenu);
    } else if (menu === 'QrGen') {
      navigate('/hr/QrGen');
    }
    else if (menu === 'Addcomment') {
    navigate('/hr/Addcomment');
    }
  };

  // Function to handle submenu item clicks
  const handleSubmenuClick = (submenu) => {
    // Navigate to the appropriate route based on the submenu item clicked
    if (submenu === 'PersonalDetails') {
      navigate('/hr/PersonalDetails');
    } else if (submenu === 'EducationalDetails') {
      navigate('/hr/EducationalDetails');
    } else if (submenu === 'ChildrenDetails') {
      navigate('/hr/ChildrenDetails');
    } else if (submenu === 'ParentDetails') {
      navigate('/hr/ParentDetails');
    } else if (submenu === 'EmergencyDetails') {
      navigate('/hr/EmergencyDetails');
    }
  };

  // Function to handle user logout
  const handleLogout = () => {
    logout(); // Call the logout function from AuthContext
    navigate('/login'); // Redirect to the login page after logout
  };

  return (
    <div className="sidebar">
      {/* Main menu item for Dashboard */}
      <div
        className={`sidebar-item ${activePage === 'dashboard' ? 'active' : ''}`}
        onClick={() => handleMenuClick('dashboard')}
      >
        <FaTachometerAlt className="icon" />
        <span>Dashboard</span>
      </div>

      {/* Main menu item for Employee Details */}
      <div
        className={`sidebar-item ${activePage === 'HrEmployeedetails' ? 'active' : ''}`}
        onClick={() => handleMenuClick('HrEmployeedetails')}
      >
        <FaUsers className="icon" />
        <span>Employee Details</span>
      </div>

      {/* Conditional rendering of submenu items */}
      {showEmployeeSubmenu && (
        <div className="submenu">
          {/* Submenu item for Personal Details */}
          <div className="submenu-item" onClick={() => handleSubmenuClick('PersonalDetails')}>
            <FaUser className="submenu-icon" />
            <span>Personal Details</span>
          </div>
          {/* Submenu item for Educational Details */}
          <div className="submenu-item" onClick={() => handleSubmenuClick('EducationalDetails')}>
            <FaGraduationCap className="submenu-icon" />
            <span>Educational Details</span>
          </div>
          {/* Submenu item for Children Details */}
          <div className="submenu-item" onClick={() => handleSubmenuClick('ChildrenDetails')}>
            <FaChild className="submenu-icon" />
            <span>Children Details</span>
          </div>
          {/* Submenu item for Parent Details */}
          <div className="submenu-item" onClick={() => handleSubmenuClick('ParentDetails')}>
            <FaUserTie className="submenu-icon" />
            <span>Parent Details</span>
          </div>
          {/* Submenu item for Emergency Details */}
          <div className="submenu-item" onClick={() => handleSubmenuClick('EmergencyDetails')}>
            <FaHeartbeat className="submenu-icon" />
            <span>Emergency Details</span>
          </div>
        </div>
      )}

      {/* Main menu item for QR Generation */}
      <div
        className={`sidebar-item ${activePage === 'QrGen' ? 'active' : ''}`}
        onClick={() => handleMenuClick('QrGen')}
      >
        <FaQrcode className="icon" />
        <span>  QR Generate</span>
      </div>

      <div
        className={`sidebar-item ${activePage === 'Addcomment' ? 'active' : ''}`}
        onClick={() => handleMenuClick('Addcomment')}
      >
        <FaCommentDots className="icon" />
        <span>Add Comment</span>
      </div>

      {/* Main menu item for Logout */}
      <div className="sidebar-item" onClick={handleLogout}>
        <FaSignOutAlt className="icon" />
        <span>Logout</span>
      </div>
    </div>
  );
};

export default HrSidebar;
