import React, { useState } from 'react';
import axios from 'axios';
import './AddHR.css';

const AddHRManager = () => {
  const [formData, setFormData] = useState({
    eid: '',
    firstName: '',
    lastName: '',
    email: '',
    employeeType: '',
    password: ''
  });

  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const validate = () => {
    let errors = {};

    if (!formData.eid.trim()) {
      errors.eid = 'EID is required';
    }

    if (!formData.firstName.trim()) {
      errors.firstName = 'First Name is required';
    }

    if (!formData.lastName.trim()) {
      errors.lastName = 'Last Name is required';
    }

    if (!formData.email.trim()) {
      errors.email = 'E-mail is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = 'E-mail address is invalid';
    }

    if (!formData.employeeType.trim()) {
      errors.employeeType = 'Employee Type is required';
    }

    if (!formData.password.trim()) {
      errors.password = 'Password is required';
    }

    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validate(formData);
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      setIsSubmitting(true);

      try {
        const response = await axios.post('https://javapaas-188839-0.cloudclusters.net/new-employee/save', formData, {
          headers: {
            'Content-Type': 'application/json'
          }
        });

        if (response.status === 200) {
          console.log('Success:', response.data);
          alert('HR Manager added successfully!');

          setFormData({
            eid: '',
            firstName: '',
            lastName: '',
            email: '',
            employeeType: '',
            password: ''
          });
        } else {
          console.error('Error:', response.data);
          alert('Failed to add HR Manager: ' + response.data.message);
        }

        setIsSubmitting(false);
      } catch (error) {
        console.error('Error:', error.response?.data || error.message);
        alert('An error occurred: ' + (error.response?.data?.message || error.message));
        setIsSubmitting(false);
      }
    }
  };

  const handleReset = () => {
    setFormData({
      eid: '',
      firstName: '',
      lastName: '',
      email: '',
      employeeType: '',
      password: ''
    });
    setFormErrors({});
  };

  return (
    <div className="form-container">
      <div className="header-bar">
        <h1>Add HR Manager</h1>
      </div>
      <form className="hr-form" onSubmit={handleSubmit} onReset={handleReset}>
        <label>EID</label>
        <input
          type="text"
          name="eid"
          value={formData.eid}
          onChange={handleChange}
          className={formErrors.eid ? 'error' : ''}
        />
        {formErrors.eid && <p className="error-text">{formErrors.eid}</p>}

        <label>First Name</label>
        <input
          type="text"
          name="firstName"
          value={formData.firstName}
          onChange={handleChange}
          className={formErrors.firstName ? 'error' : ''}
        />
        {formErrors.firstName && <p className="error-text">{formErrors.firstName}</p>}

        <label>Last Name</label>
        <input
          type="text"
          name="lastName"
          value={formData.lastName}
          onChange={handleChange}
          className={formErrors.lastName ? 'error' : ''}
        />
        {formErrors.lastName && <p className="error-text">{formErrors.lastName}</p>}

        <label>E-mail</label>
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          className={formErrors.email ? 'error' : ''}
        />
        {formErrors.email && <p className="error-text">{formErrors.email}</p>}

        <label>Employee Type</label>
        <select
          name="employeeType"
          value={formData.employeeType}
          onChange={handleChange}
          className={formErrors.employeeType ? 'error' : ''}
        >
          <option value="">Select Employee Type</option>
          <option value="Admin">Admin</option>
          <option value="HR_Manager">HR Manager</option>
        </select>
        {formErrors.employeeType && <p className="error-text">{formErrors.employeeType}</p>}

        <label>Password</label>
        <input
          type="password"
          name="password"
          value={formData.password}
          onChange={handleChange}
          className={formErrors.password ? 'error' : ''}
        />
        {formErrors.password && <p className="error-text">{formErrors.password}</p>}

        <div className="button-group">
          <button type="submit" className="submit-button" disabled={isSubmitting}>Submit</button>
          <button type="reset" className="reset-button">Clear</button>
        </div>
      </form>
    </div>
  );
};

export default AddHRManager;
