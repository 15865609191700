import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './PersonalDetails.css';

const AdminViewPersonalDetails = () => {
    const [data, setData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [isAlertVisible, setIsAlertVisible] = useState(false);
    const [isUpdateFormVisible, setIsUpdateFormVisible] = useState(false);
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [employeeImage, setEmployeeImage] = useState(null);
    const [updateData, setUpdateData] = useState({});
    const [updatedRowId, setUpdatedRowId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(8);
    const navigate = useNavigate();

    useEffect(() => {
        let isMounted = true;
        fetchEmployeeData(isMounted);
        return () => {
            isMounted = false;
        };
    }, []);

    const fetchEmployeeData = async (isMounted) => {
        setLoading(true);
        try {
            const response = await axios.get('https://javapaas-188839-0.cloudclusters.net/api/employees/all');
            if (isMounted) {
                setData(response.data);
                setLoading(false);
            }
        } catch (error) {
            console.error('Error fetching employee data:', error);
            setLoading(false);
        }
    };

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleViewClick = (employee) => {
        setSelectedEmployee(employee);
        setIsAlertVisible(true);
        setIsUpdateFormVisible(false);
        setUpdateData(employee);
        fetchEmployeeImage(employee.eid);
    };

    const handleCloseAlert = () => {
        setIsAlertVisible(false);
        setIsUpdateFormVisible(false);
        setUpdatedRowId(null);
        setEmployeeImage(null);
    };

    const fetchEmployeeImage = async (empId) => {
        try {
            const response = await axios.get(`https://javapaas-188839-0.cloudclusters.net/employees/retrieve/image/${empId}`, { responseType: 'blob' });
            const imageUrl = URL.createObjectURL(response.data);
            setEmployeeImage(imageUrl);
        } catch (error) {
            console.error('Error fetching employee image:', error);
            setEmployeeImage(null);
        }
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setUpdateData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleUpdateClick = () => {
        setIsUpdateFormVisible(true);
    };

    const handleUpdateSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        try {
            const response = await axios.put(`https://javapaas-188839-0.cloudclusters.net/api/employees/update/${selectedEmployee.eid}`, updateData);
            const updatedEmployee = response.data;
            setData(prevData => prevData.map(emp => emp.eid === updatedEmployee.eid ? updatedEmployee : emp));
            setUpdatedRowId(updatedEmployee.eid);
    
            // Re-fetch data to ensure UI reflects updates
            await fetchEmployeeData(true);
    
            setTimeout(() => {
                handleCloseAlert();
                setLoading(false);
            }, 1500);
        } catch (error) {
            console.error('Error updating employee:', error);
            setLoading(false);
        }
    };
    
    // Pagination logic
    const filteredData = data.filter(item =>
        ['eid', 'fullName', 'division', 'nic'].some(key =>
            item[key]?.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
    );
    

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
    const maxPageNumbers = 3;

    const paginate = (pageNumber) => {
        if (pageNumber > 0 && pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
        }
    };

    const getPageNumbers = () => {
        const startPage = Math.max(1, currentPage - Math.floor(maxPageNumbers / 2));
        const endPage = Math.min(totalPages, startPage + maxPageNumbers - 1);
        const pageNumbers = [];

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(i);
        }

        return pageNumbers;
    };

    const handleAddEmployee = () => {
        navigate('/hr/AddEmployee');
    };

    return (
        <div className="Admin_view-container">

        <h1 className="Admin_view-header-title-main">Personal Details</h1>
            <div className="Admin_view-search-add-container">
                <div className="Admin_view-search-input-container">
                    <input
                        type="text"
                        placeholder="Search by EID, Full Name or Division"
                        value={searchTerm}
                        onChange={handleSearch}
                        className="Admin_view-search-input"
                    />
                </div>
            </div>
            

            {loading ? (
                <div className="Admin_view-loading">Loading...</div>
            ) : (
                <table className="Admin_view-employee-table">
                    <thead>
                        <tr className="Admin_view-table-header">
                            <th colSpan="6" className="Admin_view-header-title">Personal Information</th>
                        </tr>
                        <tr className="Admin_view-table-subheader">
                            <th>EID</th>
                            <th>Start Date</th>
                            <th>Full Name</th>
                            <th>Division</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentItems.length > 0 ? (
                            currentItems.map((row, rowIndex) => (
                                <tr
                                    key={row.eid}
                                    className={`${rowIndex % 2 === 0 ? 'Admin_view-even-row' : 'Admin_view-odd-row'} ${updatedRowId === row.eid ? 'Admin_view-updated-row' : ''}`}
                                >
                                    <td>{row.eid}</td>
                                    <td>{new Date(row.startDate).toLocaleDateString()}</td>
                                    <td>{row.fullName}</td>
                                    <td>{row.division}</td>
                                    <td>
                                        <button
                                            onClick={() => handleViewClick(row)}
                                            className="Admin_view-view-button"
                                        >
                                            View
                                        </button>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="5" className="Admin_view-no-data">No data found</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            )}

<div className="Admin_view-pagination">
                <button
                    onClick={() => paginate(currentPage - 1)}
                    className={`Admin_view-pagination-button ${currentPage === 1 ? 'Admin_view-disabled-button' : ''}`}
                    disabled={currentPage === 1}
                >
                    &laquo; 
                </button>
                {getPageNumbers().map(number => (
                    <button
                        key={number}
                        onClick={() => paginate(number)}
                        className={`Admin_view-pagination-button ${currentPage === number ? 'Admin_view-active-page' : ''}`}
                    >
                        {number}
                    </button>
                ))}
                <button
                    onClick={() => paginate(currentPage + 1)}
                    className={`Admin_view-pagination-button ${currentPage === totalPages ? 'Admin_view-disabled-button' : ''}`}
                    disabled={currentPage === totalPages}
                >
                     &raquo;
                </button>
            </div>

            {isAlertVisible && selectedEmployee && (
                <div className="Admin_view-modal-overlay">
                    <div className="Admin_view-modal-content">
                        <h2 className="Admin_view-modal-title">EID: 
                            {selectedEmployee.eid} Employee Details</h2>

                        {!isUpdateFormVisible ? (
                            <div className="Admin_view-details-container">
                                <div className="Admin_view-image-section">
                                    {employeeImage ? (
                                        <img
                                            src={employeeImage}
                                            alt="Employee"
                                            className="Admin_view-employee-image"
                                            onError={() => setEmployeeImage(null)}
                                        />
                                    ) : (
                                        <div className="Admin_view-no-image">No image available</div>
                                    )}
                                </div>
                                <div className="Admin_view-details-section">
                                    <table className="Admin_view-details-table">
                                        <tbody>
                                            <tr>
                                                <td className="Admin_view-details-label">Employee ID</td>
                                                <td className="Admin_view-details-value">{selectedEmployee.employeeId}</td>
                                            </tr>
                                            <tr>
                                                <td className="Admin_view-details-label">Full Name</td>
                                                <td className="Admin_view-details-value">{selectedEmployee.fullName}</td>
                                            </tr>
                                            <tr>
                                                <td className="Admin_view-details-label">Division</td>
                                                <td className="Admin_view-details-value">{selectedEmployee.division}</td>
                                            </tr>
                                            <tr>
                                                <td className="Admin_view-details-label">Date of Birth</td>
                                                <td className="Admin_view-details-value">{new Date(selectedEmployee.dateOfBirth).toLocaleDateString()}</td>
                                            </tr>
                                            <tr>
                                                <td className="Admin_view-details-label">NIC</td>
                                                <td className="Admin_view-details-value">{selectedEmployee.nic}</td>
                                            </tr>
                                            <tr>
                                                <td className="Admin_view-details-label">Blood Group</td>
                                                <td className="Admin_view-details-value">{selectedEmployee.bloodGroup}</td>
                                            </tr>
                                            <tr>
                                                <td className="Admin_view-details-label">Gender</td>
                                                <td className="Admin_view-details-value">{selectedEmployee.gender}</td>
                                            </tr>
                                            <tr>
                                                <td className="Admin_view-details-label">Civil Status</td>
                                                <td className="Admin_view-details-value">{selectedEmployee.civilStatus}</td>
                                            </tr>
                                            <tr>
                                                <td className="Admin_view-details-label">Address</td>
                                                <td className="Admin_view-details-value">{selectedEmployee.address}</td>
                                            </tr>
                                            <tr>
                                                <td className="Admin_view-details-label">Mobile Number</td>
                                                <td className="Admin_view-details-value">{selectedEmployee.mobileNumber}</td>
                                            </tr>
                                            <tr>
                                                <td className="Admin_view-details-label">Accommodation</td>
                                                <td className="Admin_view-details-value">{selectedEmployee.accommodation}</td>
                                            </tr>
                                            <tr>
                                                <td className="Admin_view-details-label">Email</td>
                                                <td className="Admin_view-details-value">{selectedEmployee.email}</td>
                                            </tr>
                                            <tr>
                                                <td className="Admin_view-details-label">Discharge Date</td>
                                                <td className="Admin_view-details-value">{selectedEmployee.dischargeDate ? new Date(selectedEmployee.dischargeDate).toLocaleDateString() : 'N/A'}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <button onClick={handleUpdateClick} className="Admin_view-update-button">Update</button>
                                    <button onClick={handleCloseAlert} className="Admin_view-close-button">Close</button>
                                </div>
                            </div>
                        ) : (
                            <div className="Admin_view-update-form-container">
                                <div className="Admin_view-update-form">
                                    <form onSubmit={handleUpdateSubmit}>
                                        <h3 className="Admin_view-update-title">Update Employee Details</h3>
                                        <table className="Admin_view-details-table">
                                            <tbody>
                                                <tr>
                                                    <td className="Admin_view-details-label">Full Name</td>
                                                    <td className="Admin_view-details-value">
                                                        <input
                                                            type="text"
                                                            name="fullName"
                                                            value={updateData.fullName || ''}
                                                            onChange={handleInputChange}
                                                            className="Admin_view-update-input"
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="Admin_view-details-label">Division</td>
                                                    <td className="Admin_view-details-value">
                                                        <input
                                                            type="text"
                                                            name="division"
                                                            value={updateData.division || ''}
                                                            onChange={handleInputChange}
                                                            className="Admin_view-update-input"
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="Admin_view-details-label">Date of Birth</td>
                                                    <td className="Admin_view-details-value">
                                                        <input
                                                            type="date"
                                                            name="dateOfBirth"
                                                            value={updateData.dateOfBirth ? new Date(updateData.dateOfBirth).toISOString().split('T')[0] : ''}
                                                            onChange={handleInputChange}
                                                            className="Admin_view-update-input"
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="Admin_view-details-label">NIC</td>
                                                    <td className="Admin_view-details-value">
                                                        <input
                                                            type="text"
                                                            name="nic"
                                                            value={updateData.nic || ''}
                                                            onChange={handleInputChange}
                                                            className="Admin_view-update-input"
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="Admin_view-details-label">Blood Group</td>
                                                    <td className="Admin_view-details-value">
                                                        <input
                                                            type="text"
                                                            name="bloodGroup"
                                                            value={updateData.bloodGroup || ''}
                                                            onChange={handleInputChange}
                                                            className="Admin_view-update-input"
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="Admin_view-details-label">Gender</td>
                                                    <td className="Admin_view-details-value">
                                                        <input
                                                            type="text"
                                                            name="gender"
                                                            value={updateData.gender || ''}
                                                            onChange={handleInputChange}
                                                            className="Admin_view-update-input"
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="Admin_view-details-label">Civil Status</td>
                                                    <td className="Admin_view-details-value">
                                                        <input
                                                            type="text"
                                                            name="civilStatus"
                                                            value={updateData.civilStatus || ''}
                                                            onChange={handleInputChange}
                                                            className="Admin_view-update-input"
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="Admin_view-details-label">Address</td>
                                                    <td className="Admin_view-details-value">
                                                        <input
                                                            type="text"
                                                            name="address"
                                                            value={updateData.address || ''}
                                                            onChange={handleInputChange}
                                                            className="Admin_view-update-input"
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="Admin_view-details-label">Mobile Number</td>
                                                    <td className="Admin_view-details-value">
                                                        <input
                                                            type="text"
                                                            name="mobileNumber"
                                                            value={updateData.mobileNumber || ''}
                                                            onChange={handleInputChange}
                                                            className="Admin_view-update-input"
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="Admin_view-details-label">Accommodation</td>
                                                    <td className="Admin_view-details-value">
                                                        <input
                                                            type="text"
                                                            name="accommodation"
                                                            value={updateData.accommodation || ''}
                                                            onChange={handleInputChange}
                                                            className="Admin_view-update-input"
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="Admin_view-details-label">Email</td>
                                                    <td className="Admin_view-details-value">
                                                        <input
                                                            type="email"
                                                            name="email"
                                                            value={updateData.email || ''}
                                                            onChange={handleInputChange}
                                                            className="Admin_view-update-input"
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="Admin_view-details-label">Discharge Date</td>
                                                    <td className="Admin_view-details-value">
                                                        <input
                                                            type="date"
                                                            name="dischargeDate"
                                                            value={updateData.dischargeDate ? new Date(updateData.dischargeDate).toISOString().split('T')[0] : ''}
                                                            onChange={handleInputChange}
                                                            className="Admin_view-update-input"
                                                        />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <button type="submit" className="Admin_view-submit-button">Submit</button>
                                        <button type="button" onClick={handleCloseAlert} className="Admin_view-cancel-button">Cancel</button>
                                    </form>
                                </div>
                                <div className="Admin_view-update-form-bar"></div>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default AdminViewPersonalDetails;
