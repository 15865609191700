import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const LockBackButton = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Add a history state to prevent back navigation
    window.history.pushState(null, '', window.location.href);

    const handlePopState = (event) => {
      // Prevent the default back navigation
      event.preventDefault();
      // Push the user forward again to prevent them from going back
      window.history.pushState(null, '', window.location.href);

      // Optionally, navigate to a specific route or show a warning
      // navigate('/some-safe-page');
      alert('Back navigation is disabled for security reasons.');
    };

    // Listen for the back button press
    window.addEventListener('popstate', handlePopState);

    return () => {
      // Clean up the event listener on component unmount
      window.removeEventListener('popstate', handlePopState);
    };
  }, [navigate]);

  return null; // No UI is needed
};

export default LockBackButton;