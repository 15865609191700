// src/layout/HrLayout.js
import React, { useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import HrSidebar from '../components/HrSidebar';
import HrDashboard from '../Pages/HrDashboard';
import HrEmployeedetails from '../Pages/HrEmployeeDetails';
import TopHR from '../components/TopHr';
import QrGen from '../Pages/QrGen';
import AddEmployee from '../Add_Emp_Details/AddEmployee';
import PersonalDetails from '../HR_View_Data/PersonalDetails';
import EducationalDetails from '../HR_View_Data/EducationalDetails';
import ChildrenDetails from '../HR_View_Data/ChildrenDetails';
import ParentDetails from '../HR_View_Data/ParentDetails';
import EmergencyDetails from '../HR_View_Data/EmergencyDetails';
import AddComment from '../Pages/Addcomment';
import ViewComment from '../Pages/Viewcomment';
import Comments from '../Pages/Comments';
import Lock from '../LockBackButton';

// Corrected import

const HrLayout = () => {
  const [activePage, setActivePage] = useState('dashboard');

  return (
    <div className="hr-layout">
      <TopHR />
      <HrSidebar activePage={activePage} setActivePage={setActivePage} />
      <Lock/>
      <div className="main-content">
        <Routes>
          <Route exact path="/"  element={<HrDashboard />} />
          <Route  exact path="/HrEmployeedetails" element={<HrEmployeedetails />} />
          <Route exact path="/QrGen" element={<QrGen />} />
          <Route exact path="/PersonalDetails" element={<PersonalDetails />} />
          <Route exact path="/EducationalDetails" element={<EducationalDetails />} />
          <Route exact path="/ChildrenDetails" element={<ChildrenDetails />} />
          <Route exact path="/ParentDetails" element={<ParentDetails />} />
          <Route exact path="/EmergencyDetails" element={<EmergencyDetails />} />
          <Route exact path="/AddEmployee" element={<AddEmployee />} />
          <Route exact path="/AddEmployee" element={<AddEmployee />} />
          <Route exact path="/Addcomment" element={<AddComment />} />
          <Route exact path="/Viewcomment" element={<ViewComment/>}/>
          <Route exact path="/Comments" element={<Comments/>}/>
        </Routes>
      </div>
    </div>
  );
};

export default HrLayout;