import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FaSearch } from 'react-icons/fa';
import './ParentDetails.css'; 

const ParentDetails = () => {
    const [data, setData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [isAlertVisible, setIsAlertVisible] = useState(false);
    const [selectedEmployeeParents, setSelectedEmployeeParents] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(8);
    const navigate = useNavigate();

    useEffect(() => {
        fetchParentData();
    }, []);

    const fetchParentData = () => {
        setLoading(true);
        axios.get('https://javapaas-188839-0.cloudclusters.net/api/parents/all')
            .then(response => {
                setData(response.data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching parent data:', error);
                setError('Error fetching data, please try again later.');
                setLoading(false);
            });
    };

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
        setCurrentPage(1); // Reset to the first page on search
    };

    const handleViewClick = (employeeId) => {
        const parentsOfEmployee = data.filter(parent => parent.employeeId === employeeId);
        setSelectedEmployeeParents(parentsOfEmployee);
        setIsAlertVisible(true);
    };

    const handleCloseAlert = () => {
        setIsAlertVisible(false);
    };

    const filteredData = data.filter(item =>
        Object.values(item).some(value =>
            value?.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
    );

    const uniqueEmployees = [...new Map(filteredData.map(item => [item.employeeId, item])).values()];

    const handleAddParent = () => {
        navigate('/hr/AddParent');
    };

    // Pagination calculations
    const indexOfLastEmployee = currentPage * itemsPerPage;
    const indexOfFirstEmployee = indexOfLastEmployee - itemsPerPage;
    const currentItems = uniqueEmployees.slice(indexOfFirstEmployee, indexOfLastEmployee);

    const totalPages = Math.ceil(uniqueEmployees.length / itemsPerPage);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const handlePrevClick = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextClick = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const getPaginationRange = () => {
        const start = Math.max(1, currentPage - 1);
        const end = Math.min(totalPages, start + 2);
        return Array.from({ length: end - start + 1 }, (_, i) => start + i);
    };

    return (
        <div className="View_Parent_container-Hr">
            <h1 className="View_Parent_header-title-main">Parent Details</h1>
            <div className="View_Parent_search-add-container">
                <div className="View_Parent_search-input-container">
                    <input
                        type="text"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={handleSearch}
                        className="View_Parent_search-input"
                    />
                </div>
            </div>

            {loading ? (
                <div className="View_Parent_loading">Loading...</div>
            ) : error ? (
                <div className="View_Parent_error-message">{error}</div>
            ) : (
                <>
                    <table className="View_Parent_employee-table">
                        <thead>
                            
                            <tr className="View_Parent_table-subheader">
                                <th>Employee ID</th>
                                <th>Number of Parents</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems.length > 0 ? (
                                currentItems.map((row, rowIndex) => (
                                    <tr
                                        key={row.employeeId}
                                        className={rowIndex % 2 === 0 ? 'View_Parent_even-row' : 'View_Parent_odd-row'}
                                    >
                                        <td>{row.employeeId}</td>
                                        <td>{filteredData.filter(parent => parent.employeeId === row.employeeId).length}</td>
                                        <td>
                                            <button
                                                onClick={() => handleViewClick(row.employeeId)}
                                                className="View_Parent_view-button"
                                            >
                                                View
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="5" className="View_Parent_no-data">No data found</td>
                                </tr>
                            )}
                        </tbody>
                    </table>

                    {/* Pagination */}
                    <div className="comment-table-pagination">
                        <button
                            className={`comment-table-arrow-button ${currentPage === 1 ? 'comment-table-arrow-button-disabled' : ''}`}
                            onClick={handlePrevClick}
                            disabled={currentPage === 1}
                        >
                            &lt;
                        </button>
                        {getPaginationRange().map((pageNumber) => (
                            <button
                                key={pageNumber}
                                className={`comment-table-pagination-button ${pageNumber === currentPage ? 'comment-table-pagination-button-active' : ''}`}
                                onClick={() => paginate(pageNumber)}
                            >
                                {pageNumber}
                            </button>
                        ))}
                        <button
                            className={`comment-table-arrow-button ${currentPage === totalPages ? 'comment-table-arrow-button-disabled' : ''}`}
                            onClick={handleNextClick}
                            disabled={currentPage === totalPages}
                        >
                            &gt;
                        </button>
                    </div>
                </>
            )}

            {isAlertVisible && selectedEmployeeParents.length > 0 && (
                <div className="View_Parent_modal-overlay">
                    <div className="View_Parent_modal-content">
                        <h2 className="View_Parent_modal-title">Employee ID: {selectedEmployeeParents[0].employeeId} Parent Details</h2>

                          {/* Scrollable table content */}
                    <div className="view_children_table-scrollable">
                        <table className="View_Parent_details-table">
                            <thead>
                                <tr>
                                    <th>Parent ID</th>
                                    <th>Parent Name</th>
                                    <th>Relationship</th>
                                    <th>Address</th>
                                    <th>Mobile Number</th>
                                </tr>
                            </thead>
                            <tbody>
                                {selectedEmployeeParents.map(parent => (
                                    <tr key={parent.parentId}>
                                        <td>{parent.parentId}</td>
                                        <td>{parent.parentName || 'N/A'}</td>
                                        <td>{parent.relationship}</td>
                                        <td>{parent.address}</td>
                                        <td>{parent.mobileNumber}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                        <div className="View_Parent_modal-buttons">
                            <button
                                onClick={handleCloseAlert}
                                className="View_Parent_close-button"
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ParentDetails;
