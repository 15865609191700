import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import './Comments.css'; // Import the CSS file

const Comments = () => {
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editMode, setEditMode] = useState(null);
  const [updatedComment, setUpdatedComment] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8;
  const location = useLocation();
  const { eid } = location.state || {};

  const fetchCommentsByEid = async (eid) => {
    try {
      setLoading(true);
      const response = await axios.get(`https://javapaas-188839-0.cloudclusters.net/api/comments/employee/${eid}`);
      setComments(response.data.commentDto || []);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching comments:', error);
      setComments([]);
      setLoading(false);
    }
  };

  const handleUpdate = async (commentId) => {
    try {
      const updatedComments = comments.map((comment) =>
        comment.commentId === commentId ? { ...comment, comment: updatedComment } : comment
      );
      setComments(updatedComments);
      await axios.put(`https://javapaas-188839-0.cloudclusters.net/api/comments/update/${eid}`, updatedComments);
      setEditMode(null);
    } catch (error) {
      console.error('Error updating comment:', error);
    }
  };

  useEffect(() => {
    if (eid) {
      fetchCommentsByEid(eid);
    }
  }, [eid]);

  const indexOfLastComment = currentPage * itemsPerPage;
  const indexOfFirstComment = indexOfLastComment - itemsPerPage;
  const currentComments = comments.slice(indexOfFirstComment, indexOfLastComment);
  const totalPages = Math.ceil(comments.length / itemsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const nextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const prevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const getVisiblePageNumbers = () => {
    const maxPageNumbers = 3;
    const startPage = Math.max(1, currentPage - Math.floor(maxPageNumbers / 2));
    const endPage = Math.min(totalPages, startPage + maxPageNumbers - 1);
    return Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
  };

  return (
    <div className="comments-container">
      <h2 className="comments-headerTitle">View and Edit Comments for Employee {eid}</h2>
      {loading ? (
        <p>Loading comments...</p>
      ) : currentComments && currentComments.length > 0 ? (
        <>
          <table className="comments-table">
            <thead>
              <tr>
                <th className="comments-thTd">EID</th>
                <th className="comments-thTd">Full Name</th>
                <th className="comments-thTd">Division</th>
                <th className="comments-thTd">Comment</th>
                <th className="comments-thTd">Date</th>
                <th className="comments-thTd">Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentComments.map((comment) => (
                <tr key={comment.commentId}>
                  <td className="comments-thTd">{comment.eid}</td>
                  <td className="comments-thTd">{comment.fullName}</td>
                  <td className="comments-thTd">{comment.division}</td>
                  <td className="comments-thTd">
                    {editMode === comment.commentId ? (
                      <input
                        type="text"
                        value={updatedComment}
                        onChange={(e) => setUpdatedComment(e.target.value)}
                        className="comments-inputField"
                      />
                    ) : (
                      comment.comment
                    )}
                  </td>
                  <td className="comments-thTd">
                    {comment.date ? new Date(comment.date).toLocaleDateString() : 'N/A'}
                  </td>
                  <td className="comments-thTd">
                    {editMode === comment.commentId ? (
                      <>
                        <button
                          className="comments-button"
                          onClick={() => handleUpdate(comment.commentId)}
                        >
                          Save
                        </button>
                        <button
                          className="comments-button"
                          onClick={() => setEditMode(null)}
                        >
                          Cancel
                        </button>
                      </>
                    ) : (
                      <button
                        className="comments-button"
                        onClick={() => {
                          setEditMode(comment.commentId);
                          setUpdatedComment(comment.comment);
                        }}
                      >
                        Edit
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Pagination Section */}
          <div className="comments-pagination">
            <button
              onClick={prevPage}
              className="comments-paginationButton"
              disabled={currentPage === 1}
            >
              &laquo;
            </button>

            {getVisiblePageNumbers().map((pageNumber) => (
              <button
                key={pageNumber}
                onClick={() => paginate(pageNumber)}
                className={`comments-paginationButton ${
                  currentPage === pageNumber ? 'comments-paginationButtonActive' : ''
                }`}
              >
                {pageNumber}
              </button>
            ))}

            <button
              onClick={nextPage}
              className="comments-paginationButton"
              disabled={currentPage === totalPages}
            >
              &raquo;
            </button>
          </div>
        </>
      ) : (
        <p>No comments available for this employee.</p>
      )}
    </div>
  );
};

export default Comments;
