import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentAlt, faEye } from '@fortawesome/free-solid-svg-icons';
import '../Pages/CommentTable.css';

const CommentTable = () => {
  const [employees, setEmployees] = useState([]);
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const itemsPerPage = 8;
  const navigate = useNavigate();

  const fetchEmployees = async () => {
    try {
      setLoading(true);
      const response = await axios.get('https://javapaas-188839-0.cloudclusters.net/api/employees/all');
      setEmployees(response.data);
      setFilteredEmployees(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching employee data:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEmployees();
  }, []);

  const handleViewClick = (eid) => {
    navigate(`/hr/Comments`, { state: { eid } });
  };

  const handleAddClick = (employee) => {
    navigate(`/hr/Viewcomment`, { state: { employee } });
  };

  // New function to calculate years, months, and days between two dates
  const calculateDuration = (startDate, dischargeDate) => {
    if (!startDate || !dischargeDate) return '';

    const start = new Date(startDate);
    const end = new Date(dischargeDate);

    let years = end.getFullYear() - start.getFullYear();
    let months = end.getMonth() - start.getMonth();
    let days = end.getDate() - start.getDate();

    if (days < 0) {
      months -= 1;
      days += new Date(end.getFullYear(), end.getMonth(), 0).getDate();
    }

    if (months < 0) {
      years -= 1;
      months += 12;
    }

    return `${years > 0 ? `${years} years, ` : ''}${months > 0 ? `${months} months, ` : ''}${days} days`;
  };

  const indexOfLastEmployee = currentPage * itemsPerPage;
  const indexOfFirstEmployee = indexOfLastEmployee - itemsPerPage;
  const currentEmployees = filteredEmployees.slice(indexOfFirstEmployee, indexOfLastEmployee);

  const totalPages = Math.ceil(filteredEmployees.length / itemsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handlePrevClick = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextClick = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const formatDate = (date) => {
    if (!date) return '';
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(date).toLocaleDateString(undefined, options);
  };

  const getPaginationRange = () => {
    const start = Math.max(1, currentPage - 1);
    const end = Math.min(totalPages, start + 2);
    return Array.from({ length: end - start + 1 }, (_, i) => start + i);
  };

  return (
    <div className="comment-table-container">
      <h2 className="comment-table-header-title">Add Comment</h2>
      <div className="comment-table-header-container">
        <input
          type="text"
          className="comment-table-search-bar"
          placeholder="Search by EID, Name, or Division..."
          value={searchTerm}
          onChange={(event) => {
            const value = event.target.value.toLowerCase();
            setSearchTerm(value);

            const filtered = employees.filter(
              (employee) =>
                employee.eid.toString().includes(value) ||
                employee.fullName.toLowerCase().includes(value) ||
                employee.division.toLowerCase().includes(value)
            );

            setFilteredEmployees(filtered);
            setCurrentPage(1);
          }}
        />
      </div>
      
      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          <table className="comment-table-table">
            <thead>
              <tr>
                <th className="comment-table-th comment-table-th-td">EID</th>
                <th className="comment-table-th comment-table-th-td">Name</th>
                <th className="comment-table-th comment-table-th-td">Start Date</th>
                <th className="comment-table-th comment-table-th-td">Discharge Date</th>
                <th className="comment-table-th comment-table-th-td">Duration</th>
                <th className="comment-table-th comment-table-th-td">Division</th>
                <th className="comment-table-th comment-table-th-td">Comment</th>
                <th className="comment-table-th comment-table-th-td">Comment</th>
              </tr>
            </thead>
            <tbody>
              {currentEmployees.map((employee) => (
                <tr key={employee.eid}>
                  <td className="comment-table-th-td">{employee.eid}</td>
                  <td className="comment-table-th-td">{employee.fullName}</td>
                  <td className="comment-table-th-td">{formatDate(employee.startDate)}</td>
                  <td className="comment-table-th-td">{formatDate(employee.dischargeDate)}</td>
                  <td className="comment-table-th-td">
                    {calculateDuration(employee.startDate, employee.dischargeDate)}
                  </td>
                  <td className="comment-table-th-td">{employee.division}</td>
                  <td className="comment-table-th-td">
                    <button
                      className="comment-table-comment-button"
                      onClick={() => handleAddClick(employee)}
                    >
                      <FontAwesomeIcon icon={faCommentAlt} />
                      Add
                    </button>
                  </td>
                  <td className="comment-table-th-td">
                    <button
                      className="comment-table-view-comment-button"
                      onClick={() => handleViewClick(employee.eid)}
                    >
                      <FontAwesomeIcon icon={faEye} />
                      View
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="comment-table-pagination">
            <button
              className={`comment-table-arrow-button ${currentPage === 1 ? 'comment-table-arrow-button-disabled' : ''}`}
              onClick={handlePrevClick}
              disabled={currentPage === 1}
            >
              &lt;
            </button>
            {getPaginationRange().map((pageNumber) => (
              <button
                key={pageNumber}
                className={`comment-table-pagination-button ${pageNumber === currentPage ? 'comment-table-pagination-button-active' : ''}`}
                onClick={() => paginate(pageNumber)}
              >
                {pageNumber}
              </button>
            ))}
            <button
              className={`comment-table-arrow-button ${currentPage === totalPages ? 'comment-table-arrow-button-disabled' : ''}`}
              onClick={handleNextClick}
              disabled={currentPage === totalPages}
            >
              &gt;
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default CommentTable;
