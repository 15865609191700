import React from 'react';
import { FaUserCircle } from 'react-icons/fa';
import './Topbarstyle.css';
import logo from '../images/logo.png';  // Adjust path based on where your image is located

const Top = () => {
  return (
    <div className="topbar">
      <div className="logo-section">
        <img src={logo} alt="Logo" className="logo" />
        <h1 className="h1">Rangiri Holdings Pvt (Ltd)</h1>
      </div>
      
    </div>
  );
};

export default Top;