import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';

const Image = () => {
  const [formData, setFormData] = useState({
    eid: '',
    employeeImage: '',
  });

  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const fileInputRef = useRef(null);

  useEffect(() => {
    // Get the EID from localStorage
    const storedEid = localStorage.getItem('eid');
    if (storedEid) {
      setFormData((prevState) => ({
        ...prevState,
        eid: storedEid,
      }));
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      employeeImage: e.target.files[0]
    });
  };

  const validate = () => {
    let errors = {};

    if (!formData.eid.trim()) {
      errors.eid = 'EID is required';
    }

    if (!formData.employeeImage) {
      errors.employeeImage = 'Employee Image is required';
    }

    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validate();
    if (Object.keys(errors).length === 0) {
      setFormErrors({});
      setIsSubmitting(true);

      const formDataObj = new FormData();
      formDataObj.append('empId', formData.eid);
      formDataObj.append('file', formData.employeeImage);

      try {
        const response = await axios.post('https://javapaas-188839-0.cloudclusters.net/employees/upload', formDataObj, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        console.log('Image uploaded successfully:', response.data);
        alert('Profile picture saved successfully!');

        // Reset the form after successful submission
        handleReset();
      } catch (error) {
        console.error('Error uploading image:', error);
        // Handle the error appropriately
      } finally {
        setIsSubmitting(false);
      }
    } else {
      setFormErrors(errors);
      setIsSubmitting(false);
    }
  };

  const handleReset = () => {
    setFormData({
      eid: '',
      employeeImage: '',
    });
    setFormErrors({});

    // Clear the file input
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  return (
    <div style={{
      marginLeft: '0px',
      marginTop: '0px',
      maxWidth: '1200px',
      padding: '20px',
      border: '1px solid #ccc',
      borderRadius: '8px',
      backgroundColor: '#f9f9f9',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    }}>
      <div style={{
        backgroundColor: '#01008A',
        padding: '10px',
        borderRadius: '10px 10px 0 0',
        textAlign: 'center',
        marginBottom: '15px',
      }}>
        <h2 style={{ margin: 0, color: 'white',fontSize: '2em' }}>Add Profile Picture</h2>
      </div>
      <form onSubmit={handleSubmit} onReset={handleReset}>
        <div style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
          gap: '20px',
        }}>
          <div style={{ marginBottom: '15px' }}>
            <label htmlFor="eid" style={{
              display: 'block',
              marginBottom: '5px',
              fontWeight: 'bold',
              color: '#333',
            }}>EID</label>
            <input
              type="text"
              id="eid"
              name="eid"
              value={formData.eid}
              onChange={handleChange}
              style={{
                width: '100%',
                padding: '10px',
                border: `1px solid ${formErrors.eid ? 'red' : '#ddd'}`,
                borderRadius: '6px',
                boxSizing: 'border-box',
                fontSize: '16px',
              }}
            />
            {formErrors.eid && <p style={{ color: 'red', fontSize: '0.875em', marginTop: '5px' }}>{formErrors.eid}</p>}
          </div>
          <div style={{ marginBottom: '15px' }}>
            <label htmlFor="employeeImage" style={{
              display: 'block',
              marginBottom: '5px',
              fontWeight: 'bold',
              color: '#333',
            }}>Employee Image</label>
            <input
              type="file"
              id="employeeImage"
              name="employeeImage"
              onChange={handleFileChange}
              ref={fileInputRef} // Attach ref to the file input
              style={{
                width: '100%',
                padding: '0',
                border: `1px solid ${formErrors.employeeImage ? 'red' : '#ddd'}`,
                borderRadius: '6px',
                boxSizing: 'border-box',
                fontSize: '16px',
              }}
            />
            {formErrors.employeeImage && <p style={{ color: 'red', fontSize: '0.875em', marginTop: '5px' }}>{formErrors.employeeImage}</p>}
          </div>
          <div style={{ marginBottom: '15px' }}>
            <button
              type="reset"
              style={{
                backgroundColor: '#f44336',
                color: 'white',
                border: 'none',
                padding: '12px 24px',
                borderRadius: '6px',
                cursor: 'pointer',
                fontSize: '16px',
                margin: '10px',
                transition: 'background-color 0.3s ease, transform 0.2s ease',
              }}
              onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#d32f2f'}
              onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#f44336'}
              onMouseDown={(e) => e.currentTarget.style.transform = 'scale(0.98)'}
              onMouseUp={(e) => e.currentTarget.style.transform = 'scale(1.0)'}
            >
              Reset
            </button>
            <button
              type="submit"
              disabled={isSubmitting}
              style={{
                backgroundColor: isSubmitting ? '#9E9E9E' : '#4caf50',
                color: 'white',
                border: 'none',
                padding: '12px 24px',
                borderRadius: '6px',
                cursor: 'pointer',
                fontSize: '16px',
                margin: '10px',
                transition: 'background-color 0.3s ease, transform 0.2s ease',
              }}
              onMouseOver={(e) => {
                if (!isSubmitting) e.currentTarget.style.backgroundColor = '#45a049';
              }}
              onMouseOut={(e) => {
                if (!isSubmitting) e.currentTarget.style.backgroundColor = '#4caf50';
              }}
              onMouseDown={(e) => {
                if (!isSubmitting) e.currentTarget.style.transform = 'scale(0.98)';
              }}
              onMouseUp={(e) => {
                if (!isSubmitting) e.currentTarget.style.transform = 'scale(1.0)';
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Image;
