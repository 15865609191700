import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../Pages/Viewcomment.css'; // Import the CSS file

const Viewcomment = () => {
  const location = useLocation();
  const { employee } = location.state;
  const navigate = useNavigate();

  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [selectedDate, setSelectedDate] = useState(null);
  const [message, setMessage] = useState('');

  const handleAddComment = () => {
    if (newComment.trim() && selectedDate) {
      setComments([
        ...comments,
        { text: newComment, date: selectedDate.toISOString().slice(0, 10) },
      ]);
      setNewComment('');
      setSelectedDate(null);
    } else {
      setMessage('Please select a date and enter a comment.');
    }
  };

  const handleSubmitComments = async () => {
    const commentData = comments.map((comment) => ({
      eid: employee.eid,
      fullName: employee.fullName,
      division: employee.division,
      comment: comment.text,
      date: comment.date,
    }));

    try {
      const response = await axios.post(
        'https://javapaas-188839-0.cloudclusters.net/api/comments/save',
        commentData
      );

      if (response.status === 200) {
        alert('Comments saved successfully!');
        setComments([]);
      } else {
        setMessage('Error saving comments');
      }
    } catch (error) {
      console.error('Error saving comments:', error);
      setMessage('Error saving comments');
    }
  };

  const handleCloseClick = () => {
    navigate('/hr/Addcomment');
  };

  return (
    <div className="viewcomment-container">
      <h2 className="viewcomment-header">Employee Details & Comments</h2>
      <table className="viewcomment-table">
        <tbody>
          <tr>
            <td className="viewcomment-label">EID</td>
            <td className="viewcomment-value">{employee?.eid || 'N/A'}</td>
          </tr>
          <tr>
            <td className="viewcomment-label">Full Name</td>
            <td className="viewcomment-value">{employee?.fullName || 'N/A'}</td>
          </tr>
          <tr>
            <td className="viewcomment-label">Division</td>
            <td className="viewcomment-value">{employee?.division || 'N/A'}</td>
          </tr>
          <tr>
            <td className="viewcomment-label">Date</td>
            <td className="viewcomment-value">
              <DatePicker
                selected={selectedDate}
                onChange={(date) => setSelectedDate(date)}
                dateFormat="dd/MM/yyyy"
                placeholderText="Select a date"
                className="viewcomment-date-picker"
              />
            </td>
          </tr>
          <tr>
            <td className="viewcomment-label">Comments</td>
            <td className="viewcomment-value">
              <ul>
                {comments.map((comment, index) => (
                  <li key={index}>
                    {comment.text} (Date: {comment.date})
                  </li>
                ))}
              </ul>
              <textarea
                value={newComment}
                onChange={(e) => setNewComment(e.target.value)}
                placeholder="Add a comment"
                className="viewcomment-textarea"
              />
              <button onClick={handleAddComment} className="viewcomment-add-button">
                Add Comment
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      {message && <p className="viewcomment-error-message">{message}</p>}
      <div className="viewcomment-button-container">
        <button onClick={handleSubmitComments} className="viewcomment-update-button">
          Submit
        </button>
        <button className="viewcomment-close-button" onClick={handleCloseClick}>
          Close
        </button>
      </div>
    </div>
  );
};

export default Viewcomment;
