import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FaSearch } from 'react-icons/fa';
import './HrEmployeedetails.css'; // Import the CSS file

const HrEmployeedetails = () => {
    const [data, setData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [isAlertVisible, setIsAlertVisible] = useState(false);
    const [isUpdateFormVisible, setIsUpdateFormVisible] = useState(false);
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [updateData, setUpdateData] = useState({});
    const [updatedRowId, setUpdatedRowId] = useState(null);
    const [loading, setLoading] = useState(false); // Loading state
    const navigate = useNavigate();

    useEffect(() => {
        fetchEmployeeData();
    }, []);

    // Fetch employee data
    const fetchEmployeeData = () => {
        setLoading(true); // Show loading while data is being fetched
        axios.get('https://javapaas-188839-0.cloudclusters.net/api/employees/all')
            .then(response => {
                setData(response.data);
                setLoading(false); // Hide loading once data is loaded
            })
            .catch(error => {
                console.error('Error fetching employee data:', error);
                setLoading(false); // Hide loading on error
            });
    };

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleViewClick = (employee) => {
        setSelectedEmployee(employee);
        setIsAlertVisible(true);
    };

    const handleCloseAlert = () => {
        setIsAlertVisible(false);
        setIsUpdateFormVisible(false);
        setUpdatedRowId(null);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setUpdateData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleUpdateClick = () => {
        setUpdateData({ ...selectedEmployee });
        setIsUpdateFormVisible(true);
    };

    const handleUpdateSubmit = (event) => {
        event.preventDefault();
        setLoading(true); // Show loading when the update process starts

        axios.put(`https://javapaas-188839-0.cloudclusters.net/api/employees/update/${selectedEmployee.eid}`, updateData)
            .then(response => {
                const updatedEmployee = response.data;

                // Refresh the employee table
                fetchEmployeeData();

                // Highlight the updated row
                setUpdatedRowId(updatedEmployee.eid);

                // Close the update form and alert after a short delay
                setTimeout(() => {
                    handleCloseAlert();
                    setLoading(false); // Hide loading once done
                }, 1500); // Adjust the delay time as needed
            })
            .catch(error => {
                console.error('Error updating employee:', error);
                setLoading(false); // Hide loading on error
            });
    };

    const filteredData = data.filter(item =>
        Object.values(item).some(value =>
            value?.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
    );

    const handleAddEmployee = () => {
        navigate('/hr/AddEmployee');
    };

    return (
        <div className="container-Hr">
            <div className="search-add-container">
                <div className="search-input-container">
                    <FaSearch className="search-icon" />
                    <input
                        type="text"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={handleSearch}
                        className="search-input"
                    />
                </div>
                <button
                    onClick={handleAddEmployee}
                    className="add-employee-button"
                >
                    Add Employee
                </button>
            </div>

            {loading ? (
                <div className="loading">Loading...</div> // Loading indicator
            ) : (
                <table className="employee-table">
                    <thead>
                        <tr className="table-header">
                            <th colSpan="6" className="header-title">Personal Information</th>
                        </tr>
                        <tr className="table-subheader">
                            <th>EID</th>
                            <th>Employee ID</th>
                            <th>Start Date</th>
                            <th>Full Name</th>
                            <th>Division</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredData.length > 0 ? (
                            filteredData.map((row, rowIndex) => (
                                <tr
                                    key={row.eid} // Use row.eid for unique key
                                    className={`${rowIndex % 2 === 0 ? 'even-row' : 'odd-row'} ${updatedRowId === row.eid ? 'updated-row' : ''}`}
                                >
                                    <td>{row.eid}</td>
                                    <td>{row.employeeId}</td>
                                    <td>{new Date(row.startDate).toLocaleDateString()}</td>
                                    <td>{row.fullName}</td>
                                    <td>{row.division}</td>
                                    <td>
                                        <button
                                            onClick={() => handleViewClick(row)}
                                            className="view-button"
                                        >
                                            View
                                        </button>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="6" className="no-data">No data found</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            )}

            {isAlertVisible && selectedEmployee && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <h2 className="modal-title">EID: {selectedEmployee.eid} Employee Details</h2>

                        {!isUpdateFormVisible ? (
                            <div>
                                <table className="details-table">
                                    <tbody>
                                        <tr>
                                            <td className="details-label">Employee ID</td>
                                            <td className="details-value">{selectedEmployee.employeeId}</td>
                                        </tr>
                                        <tr>
                                            <td className="details-label">Full Name</td>
                                            <td className="details-value">{selectedEmployee.fullName}</td>
                                        </tr>
                                        <tr>
                                            <td className="details-label">Division</td>
                                            <td className="details-value">{selectedEmployee.division}</td>
                                        </tr>
                                        <tr>
                                            <td className="details-label">Date of Birth</td>
                                            <td className="details-value">{new Date(selectedEmployee.dateOfBirth).toLocaleDateString()}</td>
                                        </tr>
                                        <tr>
                                            <td className="details-label">NIC</td>
                                            <td className="details-value">{selectedEmployee.nic}</td>
                                        </tr>
                                        <tr>
                                            <td className="details-label">Blood Group</td>
                                            <td className="details-value">{selectedEmployee.bloodGroup}</td>
                                        </tr>
                                        <tr>
                                            <td className="details-label">Gender</td>
                                            <td className="details-value">{selectedEmployee.gender}</td>
                                        </tr>
                                        <tr>
                                            <td className="details-label">Civil Status</td>
                                            <td className="details-value">{selectedEmployee.civilStatus}</td>
                                        </tr>
                                        <tr>
                                            <td className="details-label">Address</td>
                                            <td className="details-value">{selectedEmployee.address}</td>
                                        </tr>
                                        <tr>
                                            <td className="details-label">Mobile Number</td>
                                            <td className="details-value">{selectedEmployee.mobileNumber}</td>
                                        </tr>
                                        <tr>
                                            <td className="details-label">Accommodation</td>
                                            <td className="details-value">{selectedEmployee.accommodation}</td>
                                        </tr>
                                        <tr>
                                            <td className="details-label">Email</td>
                                            <td className="details-value">{selectedEmployee.email}</td>
                                        </tr>
                                        <tr>
                                            <td className="details-label">Discharge Date</td>
                                            <td className="details-value">{selectedEmployee.dischargeDate ? new Date(selectedEmployee.dischargeDate).toLocaleDateString() : 'N/A'}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="modal-buttons">
                                    <button
                                        onClick={handleUpdateClick}
                                        className="update-button"
                                    >
                                        Update
                                    </button>
                                    <button
                                        onClick={handleCloseAlert}
                                        className="close-button"
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        ) : (
                            <div>
                                <h3 className="update-title">Update Employee Details</h3>
                                <form onSubmit={handleUpdateSubmit}>
                                    <table className="details-table">
                                        <tbody>
                                            <tr>
                                                <td className="details-label">Full Name</td>
                                                <td className="details-value">
                                                    <input
                                                        type="text"
                                                        name="fullName"
                                                        value={updateData.fullName || ''}
                                                        onChange={handleInputChange}
                                                        className="update-input"
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="details-label">Division</td>
                                                <td className="details-value">
                                                    <input
                                                        type="text"
                                                        name="division"
                                                        value={updateData.division || ''}
                                                        onChange={handleInputChange}
                                                        className="update-input"
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="details-label">Date of Birth</td>
                                                <td className="details-value">
                                                    <input
                                                        type="date"
                                                        name="dateOfBirth"
                                                        value={updateData.dateOfBirth ? new Date(updateData.dateOfBirth).toISOString().split('T')[0] : ''}
                                                        onChange={handleInputChange}
                                                        className="update-input"
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="details-label">NIC</td>
                                                <td className="details-value">
                                                    <input
                                                        type="text"
                                                        name="nic"
                                                        value={updateData.nic || ''}
                                                        onChange={handleInputChange}
                                                        className="update-input"
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="details-label">Blood Group</td>
                                                <td className="details-value">
                                                    <input
                                                        type="text"
                                                        name="bloodGroup"
                                                        value={updateData.bloodGroup || ''}
                                                        onChange={handleInputChange}
                                                        className="update-input"
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="details-label">Gender</td>
                                                <td className="details-value">
                                                    <input
                                                        type="text"
                                                        name="gender"
                                                        value={updateData.gender || ''}
                                                        onChange={handleInputChange}
                                                        className="update-input"
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="details-label">Civil Status</td>
                                                <td className="details-value">
                                                    <input
                                                        type="text"
                                                        name="civilStatus"
                                                        value={updateData.civilStatus || ''}
                                                        onChange={handleInputChange}
                                                        className="update-input"
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="details-label">Address</td>
                                                <td className="details-value">
                                                    <input
                                                        type="text"
                                                        name="address"
                                                        value={updateData.address || ''}
                                                        onChange={handleInputChange}
                                                        className="update-input"
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="details-label">Mobile Number</td>
                                                <td className="details-value">
                                                    <input
                                                        type="text"
                                                        name="mobileNumber"
                                                        value={updateData.mobileNumber || ''}
                                                        onChange={handleInputChange}
                                                        className="update-input"
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="details-label">Accommodation</td>
                                                <td className="details-value">
                                                    <input
                                                        type="text"
                                                        name="accommodation"
                                                        value={updateData.accommodation || ''}
                                                        onChange={handleInputChange}
                                                        className="update-input"
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="details-label">Email</td>
                                                <td className="details-value">
                                                    <input
                                                        type="email"
                                                        name="email"
                                                        value={updateData.email || ''}
                                                        onChange={handleInputChange}
                                                        className="update-input"
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="details-label">Discharge Date</td>
                                                <td className="details-value">
                                                    <input
                                                        type="date"
                                                        name="dischargeDate"
                                                        value={updateData.dischargeDate ? new Date(updateData.dischargeDate).toISOString().split('T')[0] : ''}
                                                        onChange={handleInputChange}
                                                        className="update-input"
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div className="modal-buttons">
                                        <button
                                            type="submit"
                                            className="submit-button"
                                        >
                                            Submit
                                        </button>
                                        <button
                                            type="button"
                                            onClick={handleCloseAlert}
                                            className="cancel-button"
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </form>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default HrEmployeedetails;
