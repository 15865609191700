import React, { useState, useEffect } from 'react';

const EducationalDetails = () => {
  const [formData, setFormData] = useState({
    eid: '',
    education: {
      attendSchool: '',
      level: '',
      ol: '',
      al: '',
      certificate1: '',
      certificate2: '',
      certificate3: '',
      diploma1: '',
      diploma2: '',
      diploma3: '',
      degree1: '',
      degree2: '',
      degree3: '',
      postGraduateQualification1: '',
      postGraduateQualification2: '',
      postGraduateQualification3: '',
      description: '',
    },
  });

  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionMessage, setSubmissionMessage] = useState('');

  useEffect(() => {
    // Get the EID from localStorage
    const storedEid = localStorage.getItem('eid');
    if (storedEid) {
      setFormData((prevState) => ({
        ...prevState,
        eid: storedEid,
      }));
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleEducationChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      education: {
        ...formData.education,
        [name]: value,
      },
    });
  };

  const validate = () => {
    let errors = {};

    if (!formData.eid.trim()) {
      errors.eid = 'EID is required';
    }

    // Additional validation can be added here for education fields

    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validate();

    if (Object.keys(errors).length === 0) {
      setFormErrors({});
      setIsSubmitting(true);

      // Concatenate education details into single strings for certificates, diplomas, degrees, and post-graduates
      let certi = formData.education.certificate1 + "\n" + formData.education.certificate2 + "\n" + formData.education.certificate3;
      let dipl = formData.education.diploma1 + "\n" + formData.education.diploma2 + "\n" + formData.education.diploma3;
      let deg = formData.education.degree1 + "\n" + formData.education.degree2 + "\n" + formData.education.degree3;
      let gradu = formData.education.postGraduateQualification1 + "\n" + formData.education.postGraduateQualification2 + "\n" + formData.education.postGraduateQualification3;
      let des = formData.education.description;

      try {
        const requestData = [{
          employeeId: formData.eid,
          attendSchool: formData.education.attendSchool,
          level: formData.education.level,
          ol: formData.education.ol,
          al: formData.education.al,
          certificate: certi,
          diploma: dipl,
          degree: deg,
          postGraduateQualification: gradu,
          description: des,
        }];

        const response = await fetch('https://javapaas-188839-0.cloudclusters.net/api/education/save', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestData),
        });

        if (response.ok) {
          const result = await response.json();
          alert(result.message);
          handleReset();
        } else {
          const errorData = await response.json();
          alert(`Error: ${errorData.message}`);
        }
      } catch (error) {
        console.error('Error submitting education data:', error);
        alert('An error occurred while submitting the data.');
      } finally {
        setIsSubmitting(false);
      }
    } else {
      setFormErrors(errors);
    }
  };

  const handleReset = () => {
    setFormData({
      eid: '',
      education: {
        attendSchool: '',
        level: '',
        ol: '',
        al: '',
        certificate1: '',
        certificate2: '',
        certificate3: '',
        diploma1: '',
        diploma2: '',
        diploma3: '',
        degree1: '',
        degree2: '',
        degree3: '',
        postGraduateQualification1: '',
        postGraduateQualification2: '',
        postGraduateQualification3: '',
        description: '',
      },
    });
    setFormErrors({});
    setSubmissionMessage('');
  };

  const formContainerStyle = {
    marginLeft: '0',
    marginTop: '0',
    maxWidth: '1200px',
    padding: '20px',
    border: '1px solid #ccc',
    borderRadius: '8px',
    backgroundColor: '#f9f9f9',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  };

  const headerBarStyle = {
    backgroundColor: '#01008A',
    padding: '10px',
    borderRadius: '10px 10px 0 0',
    textAlign: 'center',
    marginBottom: '15px',
  };

  const headerTextStyle = {
    margin: '0',
    color: 'white',
  };

  const gridContainerStyle = {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
    gap: '20px',
  };

  const gridItemStyle = {
    marginBottom: '15px',
  };

  const labelStyle = {
    display: 'block',
    marginBottom: '5px',
    fontWeight: 'bold',
    color: '#333',
  };

  const inputStyle = {
    width: '100%',
    padding: '10px',
    border: '1px solid #ddd',
    borderRadius: '6px',
    boxSizing: 'border-box',
    fontSize: '16px',
  };

  const errorInputStyle = {
    ...inputStyle,
    borderColor: 'red',
  };

  const buttonStyle = {
    backgroundColor: '#007bff',
    color: 'white',
    border: 'none',
    padding: '12px 24px',
    borderRadius: '6px',
    cursor: 'pointer',
    fontSize: '16px',
    transition: 'background-color 0.3s ease, transform 0.2s ease',
  };

  const resetButtonStyle = {
    ...buttonStyle,
    backgroundColor: '#f44336',
    margin: '10px',
  };

  const submitButtonStyle = {
    ...buttonStyle,
    backgroundColor: '#4caf50',
    margin: '10px',
  };

  const errorTextStyle = {
    color: 'red',
    fontSize: '0.875em',
    marginTop: '5px',
  };

  return (
    <div style={formContainerStyle}>
      <div style={headerBarStyle}>
        <h1 style={headerTextStyle}>Add Education Details</h1>
      </div>
      <form onSubmit={handleSubmit} onReset={handleReset}>
        <div style={gridContainerStyle}>
          <div style={gridItemStyle}>
            <label htmlFor="eid" style={labelStyle}>EID</label>
            <input
              type="text"
              id="eid"
              name="eid"
              value={formData.eid}
              onChange={handleChange}
              style={inputStyle}
            />
          </div>
          <div style={gridItemStyle}>
            <label htmlFor="attendSchool" style={labelStyle}>School Attended</label>
            <input
              type="text"
              id="attendSchool"
              name="attendSchool"
              value={formData.education.attendSchool}
              onChange={handleEducationChange}
              style={inputStyle}
            />
          </div>
          <div style={gridItemStyle}>
            <label htmlFor="level" style={labelStyle}>Level</label>
            <input
              type="text"
              id="level"
              name="level"
              value={formData.education.level}
              onChange={handleEducationChange}
              style={inputStyle}
            />
          </div>
          <div style={gridItemStyle}>
            <label htmlFor="ol" style={labelStyle}>O/L Year</label>
            <input
              type="text"
              id="ol"
              name="ol"
              value={formData.education.ol}
              onChange={handleEducationChange}
              style={inputStyle}
              placeholder="Enter O/L Year"
            />
          </div>
          <div style={gridItemStyle}>
            <label htmlFor="al" style={labelStyle}>A/L Year</label>
            <input
              type="text"
              id="al"
              name="al"
              value={formData.education.al}
              onChange={handleEducationChange}
              style={inputStyle}
              placeholder="Enter A/L Year"
            />
          </div>
        </div>

        <h3>Certificates</h3>
        <div style={gridContainerStyle}>
          <div style={gridItemStyle}>
            <input
              type="text"
              name="certificate1"
              value={formData.education.certificate1}
              onChange={handleEducationChange}
              placeholder="Certificate 1"
              style={inputStyle}
            />
          </div>
          <div style={gridItemStyle}>
            <input
              type="text"
              name="certificate2"
              value={formData.education.certificate2}
              onChange={handleEducationChange}
              placeholder="Certificate 2"
              style={inputStyle}
            />
          </div>
          <div style={gridItemStyle}>
            <input
              type="text"
              name="certificate3"
              value={formData.education.certificate3}
              onChange={handleEducationChange}
              placeholder="Certificate 3"
              style={inputStyle}
            />
          </div>
        </div>

        <h3>Diplomas</h3>
        <div style={gridContainerStyle}>
          <div style={gridItemStyle}>
            <input
              type="text"
              name="diploma1"
              value={formData.education.diploma1}
              onChange={handleEducationChange}
              placeholder="Diploma 1"
              style={inputStyle}
            />
          </div>
          <div style={gridItemStyle}>
            <input
              type="text"
              name="diploma2"
              value={formData.education.diploma2}
              onChange={handleEducationChange}
              placeholder="Diploma 2"
              style={inputStyle}
            />
          </div>
          <div style={gridItemStyle}>
            <input
              type="text"
              name="diploma3"
              value={formData.education.diploma3}
              onChange={handleEducationChange}
              placeholder="Diploma 3"
              style={inputStyle}
            />
          </div>
        </div>

        <h3>Degrees</h3>
        <div style={gridContainerStyle}>
          <div style={gridItemStyle}>
            <input
              type="text"
              name="degree1"
              value={formData.education.degree1}
              onChange={handleEducationChange}
              placeholder="Degree 1"
              style={inputStyle}
            />
          </div>
          <div style={gridItemStyle}>
            <input
              type="text"
              name="degree2"
              value={formData.education.degree2}
              onChange={handleEducationChange}
              placeholder="Degree 2"
              style={inputStyle}
            />
          </div>
          <div style={gridItemStyle}>
            <input
              type="text"
              name="degree3"
              value={formData.education.degree3}
              onChange={handleEducationChange}
              placeholder="Degree 3"
              style={inputStyle}
            />
          </div>
        </div>

        <h3>Post-Graduate Qualifications</h3>
        <div style={gridContainerStyle}>
          <div style={gridItemStyle}>
            <input
              type="text"
              name="postGraduateQualification1"
              value={formData.education.postGraduateQualification1}
              onChange={handleEducationChange}
              placeholder="Post-Graduate Qualification 1"
              style={inputStyle}
            />
          </div>
          <div style={gridItemStyle}>
            <input
              type="text"
              name="postGraduateQualification2"
              value={formData.education.postGraduateQualification2}
              onChange={handleEducationChange}
              placeholder="Post-Graduate Qualification 2"
              style={inputStyle}
            />
          </div>
          <div style={gridItemStyle}>
            <input
              type="text"
              name="postGraduateQualification3"
              value={formData.education.postGraduateQualification3}
              onChange={handleEducationChange}
              placeholder="Post-Graduate Qualification 3"
              style={inputStyle}
            />
          </div>
        </div>

        <div style={gridItemStyle}>
          <label htmlFor="description" style={labelStyle}>Description</label>
          <textarea
            name="description"
            value={formData.education.description}
            onChange={handleEducationChange}
            placeholder="Enter any additional descriptions here"
            rows="4"
            style={inputStyle}
          />
        </div>

        {formErrors.eid && <div style={errorTextStyle}>{formErrors.eid}</div>}

        <div style={{ marginTop: '20px' }}>
          <button type="submit" style={submitButtonStyle} disabled={isSubmitting}>
            {isSubmitting ? 'Submitting...' : 'Submit'}
          </button>
          <button type="reset" style={resetButtonStyle}>
            Reset
          </button>
        </div>
      </form>
    </div>
  );
};

export default EducationalDetails;
