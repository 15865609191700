import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './AuthContext';
import PrivateRoute from './PrivateRoute';
import AdminLayout from './layouts/AdminLayout';
import HrLayout from './layouts/HrLayout';
import './styles/main.scss';
import Login from './Login/Login';
import Otp from './Login/Otp';
import ChangePassword from './Login/Change_password';


function App() {
  return (
    <Router>
      <AuthProvider>
        <div className="App">
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/Otp" element={<Otp />} />
            <Route path="/change-password" element={<ChangePassword />} />
            <Route path="/" element={<Navigate to="/login" />} />
            <Route element={<PrivateRoute />}>
              <Route path="/admin/*" element={<AdminLayout />} />
              <Route path="/hr/*" element={<HrLayout />} />
         
             
            </Route>
          </Routes>
        </div>
      </AuthProvider>
    </Router>
  );
}

export default App;
