import React, { useContext, useState } from 'react';
import { FaTachometerAlt, FaUsers, FaSignOutAlt, FaUser, FaGraduationCap, FaChild, FaUserTie, FaHeartbeat } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import './Sidebar.css';
import { AuthContext } from '../AuthContext';

const AdminSidebar = ({ activePage, setActivePage }) => {
  const { logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const [showEmployeeSubmenu, setShowEmployeeSubmenu] = useState(false);

  const handleMenuClick = (menu) => {
    setActivePage(menu);
    if (menu === 'dashboard') {
      navigate('/admin');
    } else if (menu === 'HRManagerList') {
      navigate('/admin/HRManagerList');
    } else if (menu === 'employeeDetails') {
      setShowEmployeeSubmenu(!showEmployeeSubmenu);
      navigate('/admin/Employee_details');
    } else if (menu === 'QrGen') {
      navigate('/admin/QrGen');
    }
  };

  const handleSubmenuClick = (submenu) => {
    if (submenu === 'PersonalDetails') {
      navigate('/admin/Employee_details');
    } else if (submenu === 'EducationalDetails') {
      navigate('/admin/Educational_details');
    } else if (submenu === 'ChildrenDetails') {
      navigate('/admin/Children_details');
    } else if (submenu === 'ParentDetails') {
      navigate('/admin/Parent_details');
    } else if (submenu === 'EmergencyDetails') {
      navigate('/admin/Emergency_details');
    }
  };

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  return (
    <div className="sidebar">
      <div
        className={`sidebar-item ${activePage === 'dashboard' ? 'active' : ''}`}
        onClick={() => handleMenuClick('dashboard')}
      >
        <FaTachometerAlt className="icon" />
        <span>Dashboard</span>
      </div>

      <div
        className={`sidebar-item ${activePage === 'HRManagerList' ? 'active' : ''}`}
        onClick={() => handleMenuClick('HRManagerList')}
      >
        <FaUsers className="icon" />
        <span>Manage HR</span>
      </div>

      <div
        className={`sidebar-item ${activePage === 'employeeDetails' ? 'active' : ''}`}
        onClick={() => handleMenuClick('employeeDetails')}
      >
        <FaUsers className="icon" />
        <span>Employee Details</span>
      </div>

      {showEmployeeSubmenu && (
        <div className="submenu">
          <div className="submenu-item" onClick={() => handleSubmenuClick('PersonalDetails')}>
            <FaUser className="submenu-icon" />
            <span>Personal Details</span>
          </div>
          <div className="submenu-item" onClick={() => handleSubmenuClick('EducationalDetails')}>
            <FaGraduationCap className="submenu-icon" />
            <span>Educational Details</span>
          </div>
          <div className="submenu-item" onClick={() => handleSubmenuClick('ChildrenDetails')}>
            <FaChild className="submenu-icon" />
            <span>Children Details</span>
          </div>
          <div className="submenu-item" onClick={() => handleSubmenuClick('ParentDetails')}>
            <FaUserTie className="submenu-icon" />
            <span>Parent Details</span>
          </div>
          <div className="submenu-item" onClick={() => handleSubmenuClick('EmergencyDetails')}>
            <FaHeartbeat className="submenu-icon" />
            <span>Emergency Details</span>
          </div>
        </div>
      )}

      <div className="sidebar-item" onClick={handleLogout}>
        <FaSignOutAlt className="icon" />
        <span>Logout</span>
      </div>
    </div>
  );
};

export default AdminSidebar;
