import React, { useState, useEffect } from 'react';
import axios from 'axios'; // Import axios for making HTTP requests

const AddChildrensDetails = () => {
  const [formData, setFormData] = useState({
    eid: '',
    children: [{ name: '', age: '' }],
  });

  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState('');

  useEffect(() => {
    // Get the EID from localStorage
    const storedEid = localStorage.getItem('eid');
    if (storedEid) {
      setFormData((prevState) => ({
        ...prevState,
        eid: storedEid,
      }));
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleNestedChange = (e, section, index, field) => {
    const updatedSection = [...formData[section]];
    updatedSection[index][field] = e.target.value;
    setFormData({
      ...formData,
      [section]: updatedSection,
    });
  };

  const addChild = () => {
    setFormData({
      ...formData,
      children: [...formData.children, { name: '', age: '' }],
    });
  };

  const removeChild = (index) => {
    const updatedChildren = formData.children.filter((_, i) => i !== index);
    setFormData({
      ...formData,
      children: updatedChildren,
    });
  };

  const validate = () => {
    let errors = {};

    if (!formData.eid.trim()) {
      errors.eid = 'EID is required';
    }

    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validate();
    if (Object.keys(errors).length === 0) {
      setFormErrors({});
      setIsSubmitting(true);
  
      const childrenRequests = formData.children.map((child) => ({
        employeeId: formData.eid,
        childName: child.name,
        childAge: child.age,
      }));
  
      try {
        const response = await axios.post(
          'https://javapaas-188839-0.cloudclusters.net/api/children/save',
          childrenRequests
        );
  
        if (response.data.status === '200') {
          alert('Children saved successfully!');
          handleReset(); // Reset form after success
        } else {
          setSubmitStatus('Failed to save children: ' + response.data.message);
        }
      } catch (error) {
        setSubmitStatus('Error occurred while saving children: ' + error.message);
      } finally {
        setIsSubmitting(false);
      }
    } else {
      setFormErrors(errors);
      setIsSubmitting(false);
    }
  };
  

  const handleReset = () => {
    setFormData({
      eid: '',
      children: [{ name: '', age: '' }],
    });
    setFormErrors({});
    setSubmitStatus('');
  };

  return (
    <div
      style={{
        marginLeft: '0',
        marginTop: '0',
        maxWidth: '1200px',
        padding: '20px',
        border: '1px solid #ccc',
        borderRadius: '8px',
        backgroundColor: '#f9f9f9',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      }}
    >
      <div
        style={{
          backgroundColor: '#01008A',
          padding: '10px',
          borderRadius: '10px 10px 0 0',
          textAlign: 'center',
          marginBottom: '15px',
        }}
      >
        <h1 style={{ margin: '0', color: 'white' }}>Add Childrens Details</h1>
      </div>
      <form onSubmit={handleSubmit} onReset={handleReset}>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
            gap: '20px',
          }}
        >
          <div style={{ marginBottom: '15px' }}>
            <label
              htmlFor="eid"
              style={{
                display: 'block',
                marginBottom: '5px',
                fontWeight: 'bold',
                color: '#333',
              }}
            >
              EID
            </label>
            <input
              type="text"
              id="eid"
              name="eid"
              value={formData.eid}
              onChange={handleChange}
              style={{
                width: '100%',
                padding: '10px',
                border: `1px solid ${formErrors.eid ? 'red' : '#ddd'}`,
                borderRadius: '6px',
                boxSizing: 'border-box',
                fontSize: '16px',
              }}
            />
            {formErrors.eid && (
              <p style={{ color: 'red', fontSize: '0.875em', marginTop: '5px' }}>
                {formErrors.eid}
              </p>
            )}
          </div>
          <div style={{ marginBottom: '15px' }}>
            <label
              htmlFor="children"
              style={{
                display: 'block',
                marginBottom: '5px',
                fontWeight: 'bold',
                color: '#333',
              }}
            >
              Children
            </label>
            {formData.children.map((child, index) => (
              <div key={index} style={{ marginBottom: '10px' }}>
                <input
                  type="text"
                  placeholder="Name"
                  value={child.name}
                  onChange={(e) =>
                    handleNestedChange(e, 'children', index, 'name')
                  }
                  style={{
                    display: 'inline-block',
                    marginRight: '10px',
                    width: '100%',
                    padding: '10px',
                    border: '1px solid #ddd',
                    borderRadius: '6px',
                    boxSizing: 'border-box',
                    fontSize: '16px',
                  }}
                />
                <input
                  type="number"
                  placeholder="Age"
                  value={child.age}
                  onChange={(e) =>
                    handleNestedChange(e, 'children', index, 'age')
                  }
                  style={{
                    display: 'inline-block',
                    marginRight: '10px',
                    width: '100%',
                    padding: '10px',
                    border: '1px solid #ddd',
                    borderRadius: '6px',
                    boxSizing: 'border-box',
                    fontSize: '16px',
                  }}
                />
                <button
                  type="button"
                  onClick={() => removeChild(index)}
                  style={{
                    backgroundColor: '#dc3545',
                    color: 'white',
                    border: 'none',
                    padding: '12px 24px',
                    borderRadius: '6px',
                    cursor: 'pointer',
                    fontSize: '16px',
                    transition: 'background-color 0.3s ease, transform 0.2s ease',
                  }}
                >
                  Remove
                </button>
              </div>
            ))}
            <button
              type="button"
              onClick={addChild}
              style={{
                backgroundColor: '#007bff',
                color: 'white',
                border: 'none',
                padding: '12px 24px',
                borderRadius: '6px',
                cursor: 'pointer',
                fontSize: '16px',
                transition: 'background-color 0.3s ease, transform 0.2s ease',
              }}
            >
              Add Child
            </button>
          </div>
          <div style={{ marginBottom: '15px' }}>
            <button
              type="reset"
              style={{
                backgroundColor: '#f44336',
                color: 'white',
                border: 'none',
                padding: '12px 24px',
                borderRadius: '6px',
                cursor: 'pointer',
                fontSize: '16px',
                marginRight: '10px',
                transition: 'background-color 0.3s ease, transform 0.2s ease',
              }}
            >
              Reset
            </button>
            <button
              type="submit"
              disabled={isSubmitting}
              style={{
                backgroundColor: '#4caf50',
                color: 'white',
                border: 'none',
                padding: '12px 24px',
                borderRadius: '6px',
                cursor: 'pointer',
                fontSize: '16px',
                marginRight: '10px',
                transition: 'background-color 0.3s ease, transform 0.2s ease',
              }}
            >
              {isSubmitting ? 'Submitting...' : 'Submit'}
            </button>
          </div>
          {submitStatus && (
            <div
              style={{
                gridColumn: 'span 2',
                padding: '10px',
                borderRadius: '6px',
                backgroundColor:
                  submitStatus.startsWith('Children saved')
                    ? '#d4edda'
                    : '#f8d7da',
                color:
                  submitStatus.startsWith('Children saved')
                    ? '#155724'
                    : '#721c24',
                marginTop: '10px',
              }}
            >
              {submitStatus}
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default AddChildrensDetails;
