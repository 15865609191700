import React, { useState, useEffect } from 'react';

const EmergencyDetails = () => {
  const [formData, setFormData] = useState({
    eid: '',
    emergencyContact: { name: '', relationship: '', address: '', mobileNumber: '' },
  });

  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionMessage, setSubmissionMessage] = useState('');


  useEffect(() => {
    // Get the EID from localStorage
    const storedEid = localStorage.getItem('eid');
    if (storedEid) {
      setFormData((prevState) => ({
        ...prevState,
        eid: storedEid,
      }));
    }
  }, []);
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleEmergencyContactChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      emergencyContact: {
        ...formData.emergencyContact,
        [name]: value
      }
    });
  };

  const validate = () => {
    let errors = {};

    if (!formData.eid.trim()) {
      errors.eid = 'EID is required';
    }

    return errors;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validate();
    if (Object.keys(errors).length === 0) {
        setFormErrors({});
        setIsSubmitting(true);

        try {
            // Prepare the data as a list
            const requestData = [
                {
                    ...formData.emergencyContact,
                    eid: formData.eid
                }
            ];

            const response = await fetch('https://javapaas-188839-0.cloudclusters.net/api/emergency-contact/save', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestData), // Send the request data as a list
            });

            if (response.ok) {
                const data = await response.json();
                console.log(data);
                setSubmissionMessage('Emergency details saved successfully!');
                alert('Emergency details saved successfully!');
                handleReset(); // Reset the form after successful submission
            } else {
                setSubmissionMessage('Failed to save emergency details. Please try again.');
            }
        } catch (error) {
            console.error('Error:', error);
            setSubmissionMessage('An error occurred. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    } else {
        setFormErrors(errors);
        setIsSubmitting(false);
    }
};



  const handleReset = () => {
    setFormData({
      eid: '',
      emergencyContact: { name: '', relationship: '', address: '', mobileNumber: '' },
    });
    setFormErrors({});
    setSubmissionMessage('');
  };

  return (
    <div style={{ marginLeft: '0', marginTop: '0', maxWidth: '1200px', padding: '20px', border: '1px solid #ccc', borderRadius: '8px', backgroundColor: '#f9f9f9', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
      <div style={{ backgroundColor: '#01008A', padding: '10px', borderRadius: '10px 10px 0 0', textAlign: 'center', marginBottom: '15px' }}>
        <h1 style={{ margin: '0', color: 'white' }}>Add Emergency Details</h1>
      </div>
      <form className="hr-form" onSubmit={handleSubmit} onReset={handleReset}>
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))', gap: '20px' }}>
          <div style={{ marginBottom: '15px' }}>
            <label htmlFor="eid" style={{ display: 'block', marginBottom: '5px', fontWeight: 'bold', color: '#333' }}>EID</label>
            <input
              type="text"
              id="eid"
              name="eid"
              value={formData.eid}
              onChange={handleChange}
              style={{ width: '100%', padding: '10px', border: '1px solid #ddd', borderRadius: '6px', boxSizing: 'border-box', fontSize: '16px', borderColor: formErrors.eid ? 'red' : '' }}
            />
            {formErrors.eid && <p style={{ color: 'red', fontSize: '0.875em', marginTop: '5px' }}>{formErrors.eid}</p>}
          </div>
          <div style={{ marginBottom: '15px' }}>
            <label htmlFor="emergencyContact" style={{ display: 'block', marginBottom: '5px', fontWeight: 'bold', color: '#333' }}>Emergency Contact</label>
            <input
              type="text"
              id="emergencyContactName"
              name="name"
              placeholder="Name"
              value={formData.emergencyContact.name}
              onChange={handleEmergencyContactChange}
              style={{ width: '100%', padding: '10px', border: '1px solid #ddd', borderRadius: '6px', boxSizing: 'border-box', fontSize: '16px', marginBottom: '10px' }}
            />
            <input
              type="text"
              id="emergencyContactRelationship"
              name="relationship"
              placeholder="Relationship"
              value={formData.emergencyContact.relationship}
              onChange={handleEmergencyContactChange}
              style={{ width: '100%', padding: '10px', border: '1px solid #ddd', borderRadius: '6px', boxSizing: 'border-box', fontSize: '16px', marginBottom: '10px' }}
            />
            <input
              type="text"
              id="emergencyContactAddress"
              name="address"
              placeholder="Address"
              value={formData.emergencyContact.address}
              onChange={handleEmergencyContactChange}
              style={{ width: '100%', padding: '10px', border: '1px solid #ddd', borderRadius: '6px', boxSizing: 'border-box', fontSize: '16px', marginBottom: '10px' }}
            />
            <input
              type="text"
              id="emergencyContactMobileNumber"
              name="mobileNumber"
              placeholder="Mobile Number"
              value={formData.emergencyContact.mobileNumber}
              onChange={handleEmergencyContactChange}
              style={{ width: '100%', padding: '10px', border: '1px solid #ddd', borderRadius: '6px', boxSizing: 'border-box', fontSize: '16px', marginBottom: '10px' }}
            />
          </div>
          <div style={{ marginBottom: '15px' }}>
            
            <button type="reset" style={{ backgroundColor: '#f44336', color: 'white', border: 'none', padding: '12px 24px', borderRadius: '6px', cursor: 'pointer', fontSize: '16px', margin: '10px', transition: 'background-color 0.3s ease, transform 0.2s ease' }}>Reset</button>
            <button type="submit" style={{ backgroundColor: '#4caf50', color: 'white', border: 'none', padding: '12px 24px', borderRadius: '6px', cursor: 'pointer', fontSize: '16px', margin: '10px', transition: 'background-color 0.3s ease, transform 0.2s ease' }}>Submit</button>
            
          </div>
          
        </div>
      </form>
    </div>
  );
};

export default EmergencyDetails;