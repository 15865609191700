import React, { useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import AdminSidebar from '../components/AdminSidebar'; // Ensure this is exported correctly
import AdminDashboard from '../Pages/AdminDashboard'; // Ensure this is exported correctly
import Top from '../components/Top'; // Ensure this is exported correctly
import AddHRManager from '../Pages/AddHR'; // Ensure this is exported correctly
import HRManagerList from '../Pages/ViewHR'; // Ensure this is exported correctly
import AdminPersonalDetails from '../View_Emp_Details_Admin/Personal_details'; // Ensure this is exported correctly
import AdminEducationalDetails from '../View_Emp_Details_Admin/Educational_details'; // Ensure this is exported correctly
import ChildrenDetails from '../HR_View_Data/ChildrenDetails'; // Ensure this is exported correctly
import ParentDetails from '../HR_View_Data/ParentDetails'; // Ensure this is exported correctly
import EmergencyDetails from '../HR_View_Data/EmergencyDetails'; // Ensure this is exported correctly
import Lock from '../LockBackButton'; // Ensure this is exported correctly
import AdminViewPersonalDetails from '../Admin_view/AdminViewPersonalDetails';

const AdminLayout = () => {
  const [activePage, setActivePage] = useState('dashboard');

  return (
    <div className="admin-layout">
      <Top />
      <AdminSidebar activePage={activePage} setActivePage={setActivePage} />
      <Lock />
      <div className="main-content">
        <Routes>
          <Route path="/" element={<AdminDashboard />} />
          <Route path="/HRManagerList" element={<HRManagerList />} />
          <Route path="/add-HR-Manager" element={<AddHRManager />} />
          <Route path="/Educational_details" element={<AdminEducationalDetails />} />
          <Route path="/Children_details" element={<ChildrenDetails />} />
          <Route path="/Parent_details" element={<ParentDetails />} />
          <Route path="/Emergency_details" element={<EmergencyDetails />} />
          <Route path="/Employee_details" element={<AdminViewPersonalDetails />} />
        </Routes>
      </div>
    </div>
  );
};

export default AdminLayout;
