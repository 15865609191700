import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; 
import './Change_password.css';
import { FaRegUser } from "react-icons/fa";
import { CiLock } from "react-icons/ci";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import women from '../images/women.png';
import logo from '../images/logo.png';

function ChangePassword() {
  const [email, setEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState(''); // State for success or error messages
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate(); 

  const validate = () => {
    const errors = {};
    if (!email) errors.email = 'Email is required';
    if (!newPassword) errors.newPassword = 'New password is required';
    if (!confirmPassword) errors.confirmPassword = 'Confirm password is required';
    if (newPassword && confirmPassword && newPassword !== confirmPassword) {
      errors.confirmPassword = 'Passwords do not match';
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;

    try {
      const response = await fetch('https://javapaas-188839-0.cloudclusters.net/login/reset-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: email,
          password: newPassword,
        }),
      });

      if (response.ok) {
        setMessage('Password updated successfully'); // Set success message
        setTimeout(() => navigate('/login'), 2000); // Redirect after 2 seconds
      } else {
        const errorMessage = await response.text();
        setMessage(`Error: ${errorMessage}`); // Set error message
      }
    } catch (error) {
      setMessage('An error occurred while updating the password'); // Set error message
      console.error('Error:', error);
    }
  };

  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <div className="container">
      <div className="forgot-password-container">
        <div className="left-side-cp">
          <img src={logo} alt="Logo" className="logo" />
          <h1>Rangiri Holdings</h1>
          <p>for national interests</p>
          <h2>FORGOT PASSWORD</h2>

          <form className="forgot-password-form" onSubmit={handleSubmit}>
            <div className="input-container">
              <FaRegUser className="icon-2" />
              <input
                type="email"
                placeholder="Email"
                className="input-field"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {errors.email && <span className="error">{errors.email}</span>}
            </div>
            <div className="input-container">
              <CiLock className="icon-2" />
              <input
                type={showNewPassword ? "text" : "password"}
                placeholder="New Password"
                className="input-field"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              {showNewPassword ? (
                <FaEyeSlash className="eye-icon" onClick={toggleNewPasswordVisibility} />
              ) : (
                <FaEye className="eye-icon" onClick={toggleNewPasswordVisibility} />
              )}
              {errors.newPassword && <span className="error">{errors.newPassword}</span>}
            </div>
            <div className="input-container">
              <CiLock className="icon-2" />
              <input
                type={showConfirmPassword ? "text" : "password"}
                placeholder="Confirm Password"
                className="input-field"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              {showConfirmPassword ? (
                <FaEyeSlash className="eye-icon" onClick={toggleConfirmPasswordVisibility} />
              ) : (
                <FaEye className="eye-icon" onClick={toggleConfirmPasswordVisibility} />
              )}
              {errors.confirmPassword && <span className="error">{errors.confirmPassword}</span>}
            </div>
            <button type="submit" className="change-submit-button">Submit</button>
            {message && <div className={`message ${message.startsWith('Error') ? 'error' : 'success'}`}>{message}</div>}
          </form>
          <a href="/login" className="back-to-login">Back to Login</a>
        </div>
        <div className="right-side-cp">
          <div className="info-box">
            <img src={women} alt="Women" className="info-image" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChangePassword;
