import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './EducationalDetails.css';

const UpdateModal = ({ selectedDetail, closeModal, handleSave }) => {
    const [editableDetail, setEditableDetail] = useState({ ...selectedDetail });

    const handleInputChange = (field, value) => {
        setEditableDetail({ ...editableDetail, [field]: value });
    };

    const saveUpdate = () => {
        handleSave(editableDetail);
    };

    return (
        <div className="Admin_view_edu_modal-overlay" role="dialog" aria-labelledby="update-modal-title" aria-modal="true">
            <div className="Admin_view_edu_modal-content">
                <h2 id="update-modal-title" className="Admin_view_edu_modal-title">
                    Update Educational Detail
                </h2>
                <table className="Admin_view_edu_education-table">
                    <tbody>
                        {Object.keys(editableDetail).map((key) =>
                            key !== 'educationId' && key !== 'employeeId' ? (
                                <tr key={key}>
                                    <td>{key.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())}</td>
                                    <td>
                                        <input
                                            type="text"
                                            value={editableDetail[key] || ''}
                                            onChange={(e) => handleInputChange(key, e.target.value)}
                                        />
                                    </td>
                                </tr>
                            ) : null
                        )}
                    </tbody>
                </table>
                <div className="Admin_view_edu_modal-buttons">
                    <button className="Admin_view_edu_close-button" onClick={closeModal}>
                        Close
                    </button>
                    <button className="Admin_view_edu_update-button" onClick={saveUpdate}>
                        Save
                    </button>
                </div>
            </div>
        </div>
    );
};

const Modal = ({ educationDetails, closeModal, handleEdit }) => (
    <div className="Admin_view_edu_modal-overlay" role="dialog" aria-labelledby="modal-title" aria-modal="true">
        <div className="Admin_view_edu_modal-content">
            <span className="Admin_view_edu_close-icon" onClick={closeModal}>
                ✖
            </span>
            <h2 id="modal-title" className="Admin_view_edu_modal-title">
                EID {educationDetails[0]?.employeeId} Educational Details
            </h2>
            <table className="Admin_view_edu_education-table">
                <thead>
                    <tr>
                        {Object.keys(educationDetails[0] || {}).map((key) =>
                            key !== 'educationId' && key !== 'employeeId' ? (
                                <th key={key}>
                                    {key.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())}
                                </th>
                            ) : null
                        )}
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {educationDetails.map((detail, index) => (
                        <tr key={index}>
                            {Object.keys(detail).map((key) =>
                                key !== 'educationId' && key !== 'employeeId' ? (
                                    <td key={key}>
                                        {key === 'description' && detail[key] ? (
                                            <a href={detail[key]} target="_blank" rel="noopener noreferrer">
                                                {detail[key]}
                                            </a>
                                        ) : (
                                            detail[key] || ''
                                        )}
                                    </td>
                                ) : null
                            )}
                            <td>
                                <button
                                    className="Admin_view_edu_update-button"
                                    onClick={() => handleEdit(detail)}
                                >
                                    Edit
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    </div>
);

const AdminEducationalDetails = () => {
    const [educationData, setEducationData] = useState([]);
    const [uniqueEmployees, setUniqueEmployees] = useState([]);
    const [selectedEducation, setSelectedEducation] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [updateModalOpen, setUpdateModalOpen] = useState(false);
    const [selectedDetail, setSelectedDetail] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(8);

    useEffect(() => {
        fetchEducationData();
    }, []);

    useEffect(() => {
        filterUniqueEmployees();
    }, [educationData, searchTerm]);

    const fetchEducationData = async () => {
        try {
            const response = await axios.get('https://javapaas-188839-0.cloudclusters.net/api/education/all');
            setEducationData(response.data);
        } catch (error) {
            console.error('Error fetching education data:', error);
        }
    };

    const filterUniqueEmployees = () => {
        const filtered = educationData.filter((education) =>
            education.employeeId.toLowerCase().includes(searchTerm.toLowerCase())
        );
        const unique = [...new Map(filtered.map((item) => [item.employeeId, item])).values()];
        setUniqueEmployees(unique);
    };

    const handleViewClick = (employeeId) => {
        const employeeEducation = educationData.filter((ed) => ed.employeeId === employeeId);
        setSelectedEducation(employeeEducation);
        setModalOpen(true);
    };

    const handleEdit = (detail) => {
        setSelectedDetail(detail);
        setUpdateModalOpen(true);
    };

    const handleSave = async (updatedDetail) => {
        try {
            const updatedData = { ...updatedDetail };
            await axios.put(
                `https://javapaas-188839-0.cloudclusters.net/api/education/update/${updatedDetail.employeeId}`,
                [updatedData]
            );
            alert('Education details updated successfully!');
            setEducationData((prev) =>
                prev.map((item) =>
                    item.educationId === updatedDetail.educationId ? updatedDetail : item
                )
            );
            setUpdateModalOpen(false);
        } catch (error) {
            console.error('Error updating education details:', error);
            alert('Failed to update education details.');
        }
    };

    const closeModal = () => {
        setModalOpen(false);
        setSelectedEducation([]);
    };

    const closeUpdateModal = () => {
        setUpdateModalOpen(false);
        setSelectedDetail(null);
    };

    const indexOfLastEmployee = currentPage * itemsPerPage;
    const indexOfFirstEmployee = indexOfLastEmployee - itemsPerPage;
    const currentItems = uniqueEmployees.slice(indexOfFirstEmployee, indexOfLastEmployee);

    const totalPages = Math.ceil(uniqueEmployees.length / itemsPerPage);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const handlePrevClick = () => {
        if (currentPage > 1) {
            setCurrentPage((prev) => prev - 1);
        }
    };

    const handleNextClick = () => {
        if (currentPage < totalPages) {
            setCurrentPage((prev) => prev + 1);
        }
    };

    const getPaginationRange = () => {
        const delta = 2; // Adjust this to show more pages around the current
        const range = [];
        const start = Math.max(1, currentPage - delta);
        const end = Math.min(totalPages, currentPage + delta);

        for (let i = start; i <= end; i++) {
            range.push(i);
        }
        return range;
    };

    return (
        <div className="Admin_view_edu_container-Hr">
            <h1 className="Admin_view_edu_header-title-main">Educational Details</h1>
            <div className="Admin_view_edu_search-add-container">
                <input
                    className="Admin_view_edu_search-input"
                    placeholder="Search by Employee ID..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
            </div>
            <table className="Admin_view_edu_employee-table">
                <thead>
                    <tr>
                        <th>Employee ID</th>
                        <th>Number of Records</th>
                        <th>Description</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {currentItems.map((employee) => (
                        <tr key={employee.employeeId}>
                            <td>{employee.employeeId}</td>
                            <td>{educationData.filter((ed) => ed.employeeId === employee.employeeId).length}</td>
                            <td>
                                <a href={employee.description} target="_blank" rel="noopener noreferrer">
                                    {employee.description || 'No Description'}
                                </a>
                            </td>
                            <td>
                                <button
                                    className="Admin_view_edu_view-button"
                                    onClick={() => handleViewClick(employee.employeeId)}
                                >
                                    View / Update
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {/* Pagination Controls */}
            <div className="Admin_view_edu_pagination">
                <button
                    className={`Admin_view_edu_pagination-button ${currentPage === 1 ? 'disabled' : ''}`}
                    onClick={handlePrevClick}
                    disabled={currentPage === 1}
                >
                    &lt;
                </button>
                {getPaginationRange().map((pageNumber) => (
                    <button
                        key={pageNumber}
                        className={`Admin_view_edu_pagination-button ${
                            pageNumber === currentPage ? 'active' : ''
                        }`}
                        onClick={() => paginate(pageNumber)}
                    >
                        {pageNumber}
                    </button>
                ))}
                <button
                    className={`Admin_view_edu_pagination-button ${currentPage === totalPages ? 'disabled' : ''}`}
                    onClick={handleNextClick}
                    disabled={currentPage === totalPages}
                >
                    &gt;
                </button>
            </div>

            {/* Modal Components */}
            {modalOpen && (
                <Modal
                    educationDetails={selectedEducation}
                    closeModal={closeModal}
                    handleEdit={handleEdit}
                />
            )}
            {updateModalOpen && (
                <UpdateModal
                    selectedDetail={selectedDetail}
                    closeModal={closeUpdateModal}
                    handleSave={handleSave}
                />
            )}
        </div>
    );
};

export default AdminEducationalDetails;