import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { useForm } from "react-hook-form";

function Otp() {
  const { register, watch } = useForm();
  const [value, setValue] = useState(0)
  const email = watch("email");
  const otp = watch("otp");

  // State for OTP and email verification
  const [otpGenerated, setOtpGenerated] = useState(null);

  // Generate OTP
  function generateOTP() {
    return Math.floor(100000 + Math.random() * 900000);
  }

  // Validate Email
  function validateEmail(email) {
    if (email) {
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (regex.test(email)) {
        return email;
      } else {
        window.alert("Incorrect email format");
        return null;
      }
    } else {
      window.alert("Email required");
      return null;
    }
  }

  // Fetch employees
  const [response, setResponse] = useState([]);
  
  function getEmails(uri){
    fetch(uri).then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(userData => {
      console.log('User Data:', userData);
      const emails = userData.employeeRetrieveResponse.map(employee => employee.email).filter(email => email);
      setResponse(emails);
    })
    .catch(error => {
      console.error('Error:', error);
    });
  }

  // Send email
  function sendEmail(emailValid, otpGenerated) {
    setValue(otpGenerated);
    if (emailValid != null) {
      const templateParams = {
        to_name: emailValid,
        reply_to: emailValid,
        from_name: otpGenerated,
      };
  
      emailjs.init('WJW2wGJe7Y2UfP374');
      emailjs.send('service_w8ywlx9', 'template_1htqpdg', templateParams).then(
        (response) => {
          console.log('SUCCESS!', response.status, response.text);
        },
        (error) => {
          console.log('FAILED...', error);
        }
      );
    }
  }

  // Handle OTP verification
  function handleOtpVerification() {
    if (otp === value.toString()) {
      window.alert("Verification Successful!");
      window.location.assign('/change-password');
    } else {
      window.alert("Verification Failed!!!");
    }
  }

  // Send OTP
  function sendOTP() {
    const emailValid = validateEmail(email);
    const otpGenerated = generateOTP();
    setOtpGenerated(otpGenerated); // Update the OTP state

    const uri = 'https://javapaas-188839-0.cloudclusters.net/new-employee/view';
    getEmails(uri);

    setTimeout(() => { // Use setTimeout to wait for async operation
      if (response.includes(emailValid)) {
        sendEmail(emailValid, otpGenerated);
      }
    }, 8000);
  }

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      backgroundColor: '#f0f0f0'
    }}>
      <div className="otp-content" style={{
        backgroundColor: 'white',
        padding: '40px',
        borderRadius: '10px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        textAlign: 'center',
        width: '400px'
      }}>
        <h1 style={{ marginBottom: '20px' }}>Verify Account</h1>
        
        <form onSubmit={(e) => e.preventDefault()} style={{ marginBottom: '20px' }}>
          <p>Enter Your Email</p>
          <input
            type="email"
            required
            {...register("email")}
            style={{
              width: '100%',
              padding: '10px',
              borderRadius: '5px',
              border: '1px solid #ccc',
              marginBottom: '20px'
            }}
          />
          <input
            type="button"
            value="Send OTP"
            onClick={sendOTP}
            style={{
              width: '100%',
              padding: '10px',
              borderRadius: '5px',
              border: 'none',
              backgroundColor: 'green',
              color: 'white',
              cursor: 'pointer',
              transition: 'background-color 0.3s ease'
            }}
            onMouseOver={(e) => e.target.style.backgroundColor = 'darkgreen'}
            onMouseOut={(e) => e.target.style.backgroundColor = 'green'}
          />
        </form>

        <form onSubmit={(e) => e.preventDefault()}>
          <p>Enter Pass Key</p>
          <input
            type="number"
            {...register("otp")}
            style={{
              width: '100%',
              padding: '10px',
              borderRadius: '5px',
              border: '1px solid #ccc',
              marginBottom: '20px'
            }}
          />
          <input
            type="button"
            value="Verify OTP"
            onClick={handleOtpVerification}
            style={{
              width: '100%',
              padding: '10px',
              borderRadius: '5px',
              border: 'none',
              backgroundColor: 'green',
              color: 'white',
              cursor: 'pointer',
              transition: 'background-color 0.3s ease'
            }}
            onMouseOver={(e) => e.target.style.backgroundColor = 'darkgreen'}
            onMouseOut={(e) => e.target.style.backgroundColor = 'green'}
          />
        </form>
      </div>
    </div>
  );
}

export default Otp;
