import React, { useState, useEffect } from 'react';

const AddPersonalDetails = () => {
  const [formData, setFormData] = useState({
    eid: '',
    startDate: '',
    fullName: '',
    nameWithInitial: '',
    division: '',
    dateOfBirth: '',
    nic: '',
    bloodGroup: '',
    gender: '',
    civilStatus: '',
    address: '',
    mobileNumber: '',
    accommodation: '',
    email: '',
    dischargeDate: '',
    action: 1, // Initialize action to 0
  });

  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    let newFormData = { ...formData };

    if (name === 'dischargeDate') {
      newFormData.action = value ? 0 : 1; // Set action based on discharge date
    }

    newFormData[name] = value;
    setFormData(newFormData);
  };

  const validate = () => {
    let errors = {};

    if (!formData.eid.trim()) {
      errors.eid = 'EID is required';
    }

    if (!formData.startDate.trim()) {
      errors.startDate = 'Start date is required';
    }

    if (!formData.fullName.trim()) {
      errors.fullName = 'Full Name is required';
    }

    if (!formData.nameWithInitial.trim()) {
      errors.nameWithInitial = 'Name with initial is required';
    }

    if (!formData.division.trim()) {
      errors.division = 'Division is required';
    }

    if (!formData.dateOfBirth.trim()) {
      errors.dateOfBirth = 'Date of Birth is required';
    }

    if (!formData.nic.trim()) {
      errors.nic = 'NIC is required';
    }

    if (!formData.bloodGroup.trim()) {
      errors.bloodGroup = 'Blood group is required';
    }

    if (!formData.gender.trim()) {
      errors.gender = 'Gender is required';
    }

    if (!formData.civilStatus.trim()) {
      errors.civilStatus = 'Civil Status is required';
    }

    if (!formData.address.trim()) {
      errors.address = 'Address is required';
    }

    if (!formData.mobileNumber.trim()) {
      errors.mobileNumber = 'Mobile Number is required';
    }

    if (!formData.email.trim()) {
      errors.email = 'E-mail is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = 'E-mail address is invalid';
    }

    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validate();
    if (Object.keys(errors).length === 0) {
      setIsSubmitting(true);

      fetch('https://javapaas-188839-0.cloudclusters.net/api/employees/save', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then((data) => {
          console.log('Success:', data);

          // Store the EID in localStorage
          localStorage.setItem('eid', formData.eid);

          // Show alert and navigate after user clicks OK
          alert('Employee details saved successfully!');

          handleReset();
        })
        .catch((error) => {
          console.error('Error:', error);
          alert('Error saving employee details. Please try again.');
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    } else {
      setFormErrors(errors);
      setIsSubmitting(false);
    }
  };

  const handleReset = () => {
    setFormData({
      eid: '',
      startDate: '',
      fullName: '',
      nameWithInitial: '',
      division: '',
      dateOfBirth: '',
      nic: '',
      bloodGroup: '',
      gender: '',
      civilStatus: '',
      address: '',
      mobileNumber: '',
      accommodation: '',
      email: '',
      dischargeDate: '',
      action: 0, // Reset action to 0
    });
    setFormErrors({});
  };

  const containerStyle = {
    marginLeft: '0px',
    marginTop: '10px',
    maxWidth: '1200px',
    width: '100%',
    padding: isMobile ? '20px' : '20px',
    border: '1px solid #ccc',
    borderRadius: '8px',
    backgroundColor: '#f9f9f9',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    boxSizing: 'border-box',
  };

  const inputStyle = {
    width: '100%',
    padding: '10px',
    border: '1px solid #ddd',
    borderRadius: '6px',
    boxSizing: 'border-box',
    fontSize: '16px',
    maxWidth: '100%',
  };

  const buttonStyle = {
    padding: '10px 10px',
    color: '#fff',
    border: 'none',
    borderRadius: '6px',
    cursor: 'pointer',
    fontSize: '16px',
    fontWeight: 'bold',
    minWidth: '100px',
    maxWidth: '200px',
    width: '100%',
  };

  return (
    <div style={containerStyle}>
      <div
        style={{
          backgroundColor: '#01008A',
          padding: isMobile ? '10px' : '10px',
          borderRadius: '10px 10px 0 0',
          textAlign: 'center',
          marginBottom: '15px',
        }}
      >
        <h1 style={{ margin: 0, color: 'white', fontSize: isMobile ? '24px' : '32px' }}>
          Add Personal Details
        </h1>
      </div>
      <form onSubmit={handleSubmit} onReset={handleReset}>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: `repeat(auto-fit, minmax(${
              isMobile ? '200px' : '250px'
            }, 1fr))`,
            gap: '20px',
          }}
        >
          <div style={{ marginBottom: '15px' }}>
            <label
              htmlFor="eid"
              style={{
                display: 'block',
                marginBottom: '5px',
                fontWeight: 'bold',
                color: '#333',
              }}
            >
              EID
            </label>
            <input
              type="text"
              id="eid"
              name="eid"
              value={formData.eid}
              onChange={handleChange}
              style={{
                ...inputStyle,
                border: formErrors.eid ? '1px solid red' : '1px solid #ddd',
              }}
            />
            {formErrors.eid && (
              <p style={{ color: 'red', fontSize: '0.875em', marginTop: '5px' }}>
                {formErrors.eid}
              </p>
            )}
          </div>

          <div style={{ marginBottom: '15px' }}>
            <label htmlFor="startDate" style={{ display: 'block', marginBottom: '5px', fontWeight: 'bold', color: '#333' }}>Joining Date</label>
            <input
              type="date"
              id="startDate"
              name="startDate"
              value={formData.startDate}
              onChange={handleChange}
              style={{
                width: '100%',
                padding: '10px',
                border: formErrors.startDate ? '1px solid red' : '1px solid #ddd',
                borderRadius: '6px',
                boxSizing: 'border-box',
                fontSize: '16px'
              }}
            />
            {formErrors.startDate && <p style={{ color: 'red', fontSize: '0.875em', marginTop: '5px' }}>{formErrors.startDate}</p>}
          </div>
          <div style={{ marginBottom: '15px' }}>
            <label htmlFor="fullName" style={{ display: 'block', marginBottom: '5px', fontWeight: 'bold', color: '#333' }}>Full Name</label>
            <input
              type="text"
              id="fullName"
              name="fullName"
              value={formData.fullName}
              onChange={handleChange}
              style={{
                width: '100%',
                padding: '10px',
                border: formErrors.fullName ? '1px solid red' : '1px solid #ddd',
                borderRadius: '6px',
                boxSizing: 'border-box',
                fontSize: '16px'
              }}
            />
            {formErrors.fullName && <p style={{ color: 'red', fontSize: '0.875em', marginTop: '5px' }}>{formErrors.fullName}</p>}
          </div>
          <div style={{ marginBottom: '15px' }}>
            <label htmlFor="nameWithInitial" style={{ display: 'block', marginBottom: '5px', fontWeight: 'bold', color: '#333' }}>Name with Initial</label>
            <input
              type="text"
              id="nameWithInitial"
              name="nameWithInitial"
              value={formData.nameWithInitial}
              onChange={handleChange}
              style={{
                width: '100%',
                padding: '10px',
                border: formErrors.nameWithInitial ? '1px solid red' : '1px solid #ddd',
                borderRadius: '6px',
                boxSizing: 'border-box',
                fontSize: '16px'
              }}
            />
            {formErrors.nameWithInitial && <p style={{ color: 'red', fontSize: '0.875em', marginTop: '5px' }}>{formErrors.nameWithInitial}</p>}
          </div>
          <div style={{ marginBottom: '15px' }}>
            <label htmlFor="division" style={{ display: 'block', marginBottom: '5px', fontWeight: 'bold', color: '#333' }}>Company</label>
            <select
              id="division"
              name="division"
              value={formData.division}
              onChange={handleChange}
              style={{
                width: '100%',
                padding: '10px',
                border: formErrors.division ? '1px solid red' : '1px solid #ddd',
                borderRadius: '6px',
                boxSizing: 'border-box',
                fontSize: '16px'
              }}
            >
              <option value="">Select Company</option>
              <option value="Rangiri Holdings HeadOffice">Rangiri Holdings HeadOffice</option>
              <option value="Strechtech">Strechtech</option>
              <option value="Nishu Apparal">Nishu Apparal</option>
              <option value="Nishu Creation">Nishu Creation</option>
              <option value="Livinco">Livinco</option>
              <option value="Drug Mart">Drug Mart</option>
              <option value="Rangiri Aqua Action Park">Rangiri Aqua Action Park</option>
              <option value="Synergy of Heart & Soul">Synergy of Heart & Soul</option>
            </select>
            {formErrors.division && <p style={{ color: 'red', fontSize: '0.875em', marginTop: '5px' }}>{formErrors.division}</p>}
          </div>
          <div style={{ marginBottom: '15px' }}>
            <label htmlFor="dateOfBirth" style={{ display: 'block', marginBottom: '5px', fontWeight: 'bold', color: '#333' }}>Date of Birth</label>
            <input
              type="date"
              id="dateOfBirth"
              name="dateOfBirth"
              value={formData.dateOfBirth}
              onChange={handleChange}
              style={{
                width: '100%',
                padding: '10px',
                border: formErrors.dateOfBirth ? '1px solid red' : '1px solid #ddd',
                borderRadius: '6px',
                boxSizing: 'border-box',
                fontSize: '16px'
              }}
            />
            {formErrors.dateOfBirth && <p style={{ color: 'red', fontSize: '0.875em', marginTop: '5px' }}>{formErrors.dateOfBirth}</p>}
          </div>
          <div style={{ marginBottom: '15px' }}>
            <label htmlFor="nic" style={{ display: 'block', marginBottom: '5px', fontWeight: 'bold', color: '#333' }}>NIC</label>
            <input
              type="text"
              id="nic"
              name="nic"
              value={formData.nic}
              onChange={handleChange}
              style={{
                width: '100%',
                padding: '10px',
                border: formErrors.nic ? '1px solid red' : '1px solid #ddd',
                borderRadius: '6px',
                boxSizing: 'border-box',
                fontSize: '16px'
              }}
            />
            {formErrors.nic && <p style={{ color: 'red', fontSize: '0.875em', marginTop: '5px' }}>{formErrors.nic}</p>}
          </div>
          <div style={{ marginBottom: '15px' }}>
            <label htmlFor="bloodGroup" style={{ display: 'block', marginBottom: '5px', fontWeight: 'bold', color: '#333' }}>Blood Group</label>
            <select
              id="bloodGroup"
              name="bloodGroup"
              value={formData.bloodGroup}
              onChange={handleChange}
              style={{
                width: '100%',
                padding: '10px',
                border: formErrors.bloodGroup ? '1px solid red' : '1px solid #ddd',
                borderRadius: '6px',
                boxSizing: 'border-box',
                fontSize: '16px'
              }}
            >
             <option value="">Select Blood Group</option>
              <option value="A Positive">(A+) Positive</option>
              <option value="A Negative">(A-) Negative</option>
              <option value="B Positive">(B+) Positive</option>
              <option value="B Negative">(B-) Negative</option>
              <option value="O Positive">(O+) Positive</option>
              <option value="O Negative">(O-) Negative</option>
              <option value="AB Positive">(AB+) Positive</option>
              <option value="AB Negative">(AB-) Negative</option>
            </select>
            {formErrors.bloodGroup && <p style={{ color: 'red', fontSize: '0.875em', marginTop: '5px' }}>{formErrors.bloodGroup}</p>}
          </div>
          <div style={{ marginBottom: '15px' }}>
            <label htmlFor="gender" style={{ display: 'block', marginBottom: '5px', fontWeight: 'bold', color: '#333' }}>Gender</label>
            <select
              id="gender"
              name="gender"
              value={formData.gender}
              onChange={handleChange}
              style={{
                width: '100%',
                padding: '10px',
                border: formErrors.gender ? '1px solid red' : '1px solid #ddd',
                borderRadius: '6px',
                boxSizing: 'border-box',
                fontSize: '16px'
              }}
            >
              <option value="">Select Gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Other">Other</option>
            </select>
            {formErrors.gender && <p style={{ color: 'red', fontSize: '0.875em', marginTop: '5px' }}>{formErrors.gender}</p>}
          </div>
          <div style={{ marginBottom: '15px' }}>
            <label htmlFor="civilStatus" style={{ display: 'block', marginBottom: '5px', fontWeight: 'bold', color: '#333' }}>Civil Status</label>
            <select
              id="civilStatus"
              name="civilStatus"
              value={formData.civilStatus}
              onChange={handleChange}
              style={{
                width: '100%',
                padding: '10px',
                border: formErrors.civilStatus ? '1px solid red' : '1px solid #ddd',
                borderRadius: '6px',
                boxSizing: 'border-box',
                fontSize: '16px'
              }}
            >
              <option value="">Select Status</option>
              <option value="Single">Single</option>
              <option value="Married">Married</option>
              <option value="Divorced">Divorced</option>
            </select>
            {formErrors.civilStatus && <p style={{ color: 'red', fontSize: '0.875em', marginTop: '5px' }}>{formErrors.civilStatus}</p>}
          </div>
          <div style={{ marginBottom: '15px' }}>
            <label htmlFor="address" style={{ display: 'block', marginBottom: '5px', fontWeight: 'bold', color: '#333' }}>Address</label>
            <input
              type="text"
              id="address"
              name="address"
              value={formData.address}
              onChange={handleChange}
              style={{
                width: '100%',
                padding: '10px',
                border: formErrors.address ? '1px solid red' : '1px solid #ddd',
                borderRadius: '6px',
                boxSizing: 'border-box',
                fontSize: '16px'
              }}
            />
            {formErrors.address && <p style={{ color: 'red', fontSize: '0.875em', marginTop: '5px' }}>{formErrors.address}</p>}
          </div>
          <div style={{ marginBottom: '15px' }}>
            <label htmlFor="mobileNumber" style={{ display: 'block', marginBottom: '5px', fontWeight: 'bold', color: '#333' }}>Mobile Number</label>
            <input
              type="text"
              id="mobileNumber"
              name="mobileNumber"
              value={formData.mobileNumber}
              onChange={handleChange}
              style={{
                width: '100%',
                padding: '10px',
                border: formErrors.mobileNumber ? '1px solid red' : '1px solid #ddd',
                borderRadius: '6px',
                boxSizing: 'border-box',
                fontSize: '16px'
              }}
            />
            {formErrors.mobileNumber && <p style={{ color: 'red', fontSize: '0.875em', marginTop: '5px' }}>{formErrors.mobileNumber}</p>}
          </div>
          <div style={{ marginBottom: '15px' }}>
            <label htmlFor="accommodation" style={{ display: 'block', marginBottom: '5px', fontWeight: 'bold', color: '#333' }}>Accommodation</label>
            <input
              type="text"
              id="accommodation"
              name="accommodation"
              value={formData.accommodation}
              onChange={handleChange}
              style={{
                width: '100%',
                padding: '10px',
                border: '1px solid #ddd',
                borderRadius: '6px',
                boxSizing: 'border-box',
                fontSize: '16px'
              }}
            />
          </div>
          <div style={{ marginBottom: '15px' }}>
            <label htmlFor="email" style={{ display: 'block', marginBottom: '5px', fontWeight: 'bold', color: '#333' }}>E-mail</label>
            <input
              type="text"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              style={{
                width: '100%',
                padding: '10px',
                border: formErrors.email ? '1px solid red' : '1px solid #ddd',
                borderRadius: '6px',
                boxSizing: 'border-box',
                fontSize: '16px'
              }}
            />
            {formErrors.email && <p style={{ color: 'red', fontSize: '0.875em', marginTop: '5px' }}>{formErrors.email}</p>}
          </div>

         
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '20px',
              flexWrap: 'wrap',
              gap: '10px',
            }}
          >
            <button
              type="button"
              onClick={handleReset}
              style={{
                ...buttonStyle,
                backgroundColor: 'red',
              }}
            >
              Reset
            </button>
            <button
              type="button"
              onClick={handleSubmit}
              disabled={isSubmitting}
              style={{
                ...buttonStyle,
                backgroundColor: '#4caf50',
                opacity: isSubmitting ? 0.7 : 1,
                cursor: isSubmitting ? 'not-allowed' : 'pointer',
              }}
            >
              {isSubmitting ? 'Submitting...' : 'Submit'}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddPersonalDetails;