import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FaSearch } from 'react-icons/fa';
import './EmergencyDetails.css';

const EmergencyDetails = () => {
    const [data, setData] = useState([]); // Data from API
    const [searchTerm, setSearchTerm] = useState(''); // Search term
    const [isAlertVisible, setIsAlertVisible] = useState(false); // Modal visibility
    const [selectedEmployeeContacts, setSelectedEmployeeContacts] = useState([]); // Employee contacts for modal
    const [loading, setLoading] = useState(false); // Loading state
    const [error, setError] = useState(null); // Error state
    const [currentPage, setCurrentPage] = useState(1); // Pagination current page
    const [itemsPerPage] = useState(8); // Number of items per page
    const navigate = useNavigate();

    // Fetch emergency data from API
    useEffect(() => {
        fetchEmergencyData(); // Fetch API data on component mount
    }, []);

    const fetchEmergencyData = () => {
        setLoading(true);
        axios.get('https://javapaas-188839-0.cloudclusters.net/api/emergency-contact/all')
            .then(response => {
                setData(response.data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching emergency data:', error);
                setError('Error fetching data, please try again later.');
                setLoading(false);
            });
    };

    // Handle search input
    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
        setCurrentPage(1);
    };

    // View contact details for selected employee
    const handleViewClick = (employeeId) => {
        const contactsOfEmployee = data.filter(contact => contact.eid === employeeId);
        setSelectedEmployeeContacts(contactsOfEmployee);
        setIsAlertVisible(true);
    };

    // Close modal
    const handleCloseAlert = () => {
        setIsAlertVisible(false);
    };

    // Filter the data based on the search term
    const filteredData = data.filter(item =>
        Object.values(item).some(value =>
            value?.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
    );

    // Ensure unique employees
    const uniqueEmployees = [...new Map(filteredData.map(item => [item.eid, item])).values()];

    // Handle pagination logic
    const indexOfLastEmployee = currentPage * itemsPerPage;
    const indexOfFirstEmployee = indexOfLastEmployee - itemsPerPage;
    const currentItems = uniqueEmployees.slice(indexOfFirstEmployee, indexOfLastEmployee);

    // Total pages for pagination
    const totalPages = Math.ceil(uniqueEmployees.length / itemsPerPage);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const handlePrevClick = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextClick = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    // Get pagination range
    const getPaginationRange = () => {
        const start = Math.max(1, currentPage - 1);
        const end = Math.min(totalPages, start + 2);
        return Array.from({ length: end - start + 1 }, (_, i) => start + i);
    };

    return (
        <div className="View_Emergency_container-Hr">
            <h1 className="View_Emergency_header-title-main">Emergency Details</h1>
            <div className="View_Emergency_search-add-container">
                <div className="View_Emergency_search-input-container">
                    <input
                        type="text"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={handleSearch}
                        className="View_Emergency_search-input"
                    />
                
                </div>
            </div>

            {loading ? (
                <div className="View_Emergency_loading">Loading...</div>
            ) : error ? (
                <div className="View_Emergency_error-message">{error}</div>
            ) : (
                <>
                    <table className="View_Emergency_employee-table">
                        <thead>
                            <tr className="View_Emergency_table-subheader">
                                <th>Employee ID</th>
                                <th>Number of Contacts</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems.length > 0 ? (
                                currentItems.map((row, rowIndex) => (
                                    <tr
                                        key={row.eid}
                                        className={rowIndex % 2 === 0 ? 'View_Emergency_even-row' : 'View_Emergency_odd-row'}
                                    >
                                        <td>{row.eid}</td>
                                        <td>{data.filter(contact => contact.eid === row.eid).length}</td>
                                        <td>
                                            <button
                                                onClick={() => handleViewClick(row.eid)}
                                                className="View_Emergency_view-button"
                                            >
                                                View
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="3" className="View_Emergency_no-data">No data found</td>
                                </tr>
                            )}
                        </tbody>
                    </table>

                    {/* Pagination */}
                    <div className="comment-table-pagination">
                        <button
                            className={`comment-table-arrow-button ${currentPage === 1 ? 'comment-table-arrow-button-disabled' : ''}`}
                            onClick={handlePrevClick}
                            disabled={currentPage === 1}
                        >
                            &lt;
                        </button>
                        {getPaginationRange().map((pageNumber) => (
                            <button
                                key={pageNumber}
                                className={`comment-table-pagination-button ${pageNumber === currentPage ? 'comment-table-pagination-button-active' : ''}`}
                                onClick={() => paginate(pageNumber)}
                            >
                                {pageNumber}
                            </button>
                        ))}
                        <button
                            className={`comment-table-arrow-button ${currentPage === totalPages ? 'comment-table-arrow-button-disabled' : ''}`}
                            onClick={handleNextClick}
                            disabled={currentPage === totalPages}
                        >
                            &gt;
                        </button>
                    </div>
                </>
            )}

            {isAlertVisible && selectedEmployeeContacts.length > 0 && (
                <div className="View_Emergency_modal-overlay">
                    <div className="View_Emergency_modal-content">
                        <h2 className="View_Emergency_modal-title">Employee ID: {selectedEmployeeContacts[0].eid} Emergency Contacts</h2>

                         {/* Scrollable table content */}
                         <div className="view_children_table-scrollable">
                        <table className="View_Emergency_details-table">
                            <thead>
                                <tr>
                                    <th>Contact ID</th>
                                    <th>Name</th>
                                    <th>Relationship</th>
                                    <th>Address</th>
                                    <th>Mobile Number</th>
                                </tr>
                            </thead>
                            <tbody>
                                {selectedEmployeeContacts.map(contact => (
                                    <tr key={contact.contactId}>
                                        <td>{contact.contactId}</td>
                                        <td>{contact.name}</td>
                                        <td>{contact.relationship}</td>
                                        <td>{contact.address}</td>
                                        <td>{contact.mobileNumber}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                        <div className="View_Emergency_modal-buttons">
                            <button
                                onClick={handleCloseAlert}
                                className="View_Emergency_close-button"
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default EmergencyDetails;
