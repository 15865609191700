import React, { useState, useEffect } from 'react';
import './ViewHR.css';
import { useNavigate } from 'react-router-dom';

const HRManagerList = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [employees, setEmployees] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); // State to keep track of the current page
  const employeesPerPage = 8; // Number of employees per page
  const navigate = useNavigate();

  useEffect(() => {
    fetchEmployees();
  }, []);

  const fetchEmployees = async () => {
    try {
      const response = await fetch('https://javapaas-188839-0.cloudclusters.net/new-employee/view');
      const data = await response.json();
      console.log('Response Data:', data);

      if (data.employeeRetrieveResponse) {
        setEmployees(data.employeeRetrieveResponse);
      } else {
        console.error('No employeeRetrieveResponse property in response:', data);
      }
    } catch (error) {
      console.error('Error fetching employees:', error);
    }
  };

  const handleAddEmployee = () => {
    navigate('/admin/add-HR-Manager');
  };

  const toggleEmployeeStatus = async (employeeId, isActive) => {
    const endpoint = isActive ? 'soft-delete' : 'soft-active';
    try {
      const response = await fetch(`https://javapaas-188839-0.cloudclusters.net/new-employee/${employeeId}/${endpoint}`, {
        method: 'PUT',
      });
      console.log(`Endpoint: ${endpoint}, Status: ${response.status}`); // Debugging line
      if (response.ok) {
        fetchEmployees();
        alert(`Employee ${isActive ? 'deactivated' : 'activated'} successfully.`);
      } else {
        const errorData = await response.json();
        console.error(`Error ${isActive ? 'deactivating' : 'activating'} employee:`, errorData);
        alert(`Error ${isActive ? 'deactivating' : 'activating'} employee: ${errorData.message || 'Unknown error'}`);
      }
    } catch (error) {
      console.error(`Error ${isActive ? 'deactivating' : 'activating'} employee:`, error);
      alert(`Error ${isActive ? 'deactivating' : 'activating'} employee: ${error.message || 'Unknown error'}`);
    }
  };

  const handleDisable = (employeeId) => {
    toggleEmployeeStatus(employeeId, true);
  };

  const handleEnable = (employeeId) => {
    toggleEmployeeStatus(employeeId, false);
  };

  // Pagination logic
  const indexOfLastEmployee = currentPage * employeesPerPage;
  const indexOfFirstEmployee = indexOfLastEmployee - employeesPerPage;
  const currentEmployees = employees.filter(employee => 
    employee.eid.toLowerCase().includes(searchTerm.toLowerCase()) || 
    employee.firstName.toLowerCase().includes(searchTerm.toLowerCase())
  ).slice(indexOfFirstEmployee, indexOfLastEmployee);

  const totalPages = Math.ceil(employees.length / employeesPerPage);

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className="hr-manager-list">
      <div className="search-bar-container">
        <button className="add-button" onClick={handleAddEmployee}>Add HR Manager</button>
       
        <div className="search-container">
          <input
            type="text"
            placeholder="Search by EID or Name"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="search-bar"
          />
        </div>
      </div>

      <table className="hr-table">
        <thead>
          <tr>
            <th>EID</th>
            <th>First Name</th>
            <th>E-mail</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {currentEmployees.map((employee) => (
            <tr key={employee.eid}>
              <td>{employee.eid}</td>
              <td>{employee.firstName}</td>
              <td>{employee.email}</td>
              <td>
                <button
                  onClick={() => handleDisable(employee.hr)}
                  className={`action-button ${employee.isActive ? 'active' : 'disabled'}`}
                  disabled={!employee.isActive}
                >
                  Active
                </button>
                <button
                  onClick={() => handleEnable(employee.hr)}
                  className={`action-button ${employee.isActive ? 'disabled' : 'inactive'}`}
                  disabled={employee.isActive}
                >
                  Inactive
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="pagination">
        <button
          onClick={goToPreviousPage}
          disabled={currentPage === 1}
          className="pagination-button"
        >
          &lt; 
        </button>
        <span> {currentPage} of {totalPages}</span>
        <button
          onClick={goToNextPage}
          disabled={currentPage === totalPages}
          className="pagination-button"
        >
          &gt;
        </button>
      </div>
    </div>
  );
};

export default HRManagerList;
