import React, { useState, useContext } from 'react';
import './Login.css';
import { FaRegUser } from "react-icons/fa";
import { CiLock } from "react-icons/ci";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../AuthContext';
import logo from '../images/logo.png';
import women from '../images/women.png';
import OTP from '../Login/Otp';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [employeeType, setEmployeeType] = useState('');
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();

  const validate = () => {
    const errors = {};
    if (!email) {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = 'Email address is invalid';
    }
    if (!password) errors.password = 'Password is required';
    if (!employeeType) errors.employeeType = 'Employee Type is required';
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;

    try {
      const response = await fetch('https://javapaas-188839-0.cloudclusters.net/login/user', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password, employeeType })
      });

      if (response.ok) {
        const result = await response.json();
        if (result.status === "200") {
          login(result.token); // Assuming the backend returns a token
          if (employeeType === 'Admin') {
            navigate('/admin');
          } else if (employeeType === 'HR_Manager') {
            navigate('/hr');
          }
        } else {
          setErrors({ general: result.message });
          alert(result.message); // Alert box for error message
        }
      } else {
        setErrors({ general: 'Something went wrong' });
        alert('Something went wrong'); // Alert box for general error
      }
    } catch (error) {
      setErrors({ general: 'Network error' });
      alert('Network error'); // Alert box for network error
    }
  };

  const handleClear = () => {
    setEmail('');
    setPassword('');
    setEmployeeType('');
    setErrors({});
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="container">
      <div className="login-container">
        <div className="left-side">
          <img src={logo} alt="Logo" className="logo" />
          <h1>Rangiri Holdings</h1>
          <p>for national interests</p>
          <h2>LOGIN</h2>

          <form className="login-form" onSubmit={handleSubmit}>
            {errors.general && <span className="error">{errors.general}</span>}
            <div className="input-container">
              <FaRegUser className="icon-1" />
              <input
                type="email"
                placeholder="Email"
                className="input-field"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {errors.email && <span className="error">{errors.email}</span>}
            </div>
            <div className="input-container">
              <CiLock className="icon-1" />
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                className="input-field"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              {showPassword ? (
                <FaEyeSlash className="eye-icon" onClick={togglePasswordVisibility} />
              ) : (
                <FaEye className="eye-icon" onClick={togglePasswordVisibility} />
              )}
              {errors.password && <span className="error">{errors.password}</span>}
            </div>
            <div className="input-container">
              <select
                className="input-field"
                value={employeeType}
                onChange={(e) => setEmployeeType(e.target.value)}
              >
                <option value="">Select Employee Type</option>
                <option value="Admin">Admin</option>
                <option value="HR_Manager">HR Manager</option>
              </select>
              {errors.employeeType && <span className="error">{errors.employeeType}</span>}
            </div>

            <Link to="/otp" className="forgot-password">Forgot Password?</Link>

            <div className="button-container">
              <button type="submit" className="login-button">Login Now</button>
              <button type="button" className="clear-button" onClick={handleClear}>Clear</button>
            </div>
          </form>
        </div>
        <div className="right-side">
          <div className="info-box">
            <img src={women} alt="Women" className="info-image" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
