import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AddParentsDetails = () => {
  const [formData, setFormData] = useState({
    eid: '',
    parents: [{ name: '', relationship: '', address: '', mobileNumber: '' }],
  });

  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    // Get the EID from localStorage
    const storedEid = localStorage.getItem('eid');
    if (storedEid) {
      setFormData((prevState) => ({
        ...prevState,
        eid: storedEid,
      }));
    }
  }, []);
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleNestedChange = (e, index, field) => {
    const updatedParents = [...formData.parents];
    updatedParents[index][field] = e.target.value;
    setFormData({
      ...formData,
      parents: updatedParents,
    });
  };

  const addParent = () => {
    setFormData({
      ...formData,
      parents: [...formData.parents, { name: '', relationship: '', address: '', mobileNumber: '' }],
    });
  };

  const removeParent = (index) => {
    const updatedParents = formData.parents.filter((_, i) => i !== index);
    setFormData({
      ...formData,
      parents: updatedParents,
    });
  };

  const validate = () => {
    let errors = {};

    if (!formData.eid.trim()) {
      errors.eid = 'EID is required';
    }

    formData.parents.forEach((parent, index) => {
      if (!parent.name.trim()) {
        errors[`parentName${index}`] = 'Name is required';
      }
      if (!parent.relationship.trim()) {
        errors[`relationship${index}`] = 'Relationship is required';
      }
      if (!parent.address.trim()) {
        errors[`address${index}`] = 'Address is required';
      }
      if (!parent.mobileNumber.trim()) {
        errors[`mobileNumber${index}`] = 'Mobile Number is required';
      }
    });

    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validate();
    if (Object.keys(errors).length === 0) {
      setFormErrors({});
      setIsSubmitting(true);

      try {
        const response = await axios.post(
          'https://javapaas-188839-0.cloudclusters.net/api/parents/save',
          formData.parents.map(parent => ({
            employeeId: formData.eid,
            parentName: parent.name.trim(),
            relationship: parent.relationship.trim(),
            address: parent.address.trim(),
            mobileNumber: parent.mobileNumber.trim(),
          })),
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

        if (response.status === 200) {
          alert('Parents details saved successfully!');
          handleReset();
        } else {
          throw new Error('Network response was not ok');
        }
      } catch (error) {
        console.error('Error:', error);
        alert('An error occurred while saving the data. Please try again.');
      } finally {
        setIsSubmitting(false);
      }
    } else {
      setFormErrors(errors);
      setIsSubmitting(false);
    }
  };

  const handleReset = () => {
    setFormData({
      eid: '',
      parents: [{ name: '', relationship: '', address: '', mobileNumber: '' }],
    });
    setFormErrors({});
  };

  return (
    <div
      style={{
        marginLeft: '0',
        marginTop: '0',
        maxWidth: '1200px',
        padding: '20px',
        border: '1px solid #ccc',
        borderRadius: '8px',
        backgroundColor: '#f9f9f9',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      }}
    >
      <div
        style={{
          backgroundColor: '#01008A',
          padding: '10px',
          borderRadius: '10px 10px 0 0',
          textAlign: 'center',
          marginBottom: '15px',
        }}
      >
        <h1 style={{ margin: '0', color: 'white' }}>Add Parents Details</h1>
      </div>
      <form onSubmit={handleSubmit} onReset={handleReset}>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
            gap: '20px',
          }}
        >
          <div style={{ marginBottom: '15px' }}>
            <label
              htmlFor="eid"
              style={{
                display: 'block',
                marginBottom: '5px',
                fontWeight: 'bold',
                color: '#333',
              }}
            >
              EID
            </label>
            <input
              type="text"
              id="eid"
              name="eid"
              value={formData.eid}
              onChange={handleChange}
              style={{
                width: '100%',
                padding: '10px',
                border: `1px solid ${formErrors.eid ? 'red' : '#ddd'}`,
                borderRadius: '6px',
                boxSizing: 'border-box',
                fontSize: '16px',
              }}
            />
            {formErrors.eid && (
              <p style={{ color: 'red', fontSize: '0.875em', marginTop: '5px' }}>
                {formErrors.eid}
              </p>
            )}
          </div>

          <div style={{ marginBottom: '15px' }}>
            <label
              htmlFor="parents"
              style={{
                display: 'block',
                marginBottom: '5px',
                fontWeight: 'bold',
                color: '#333',
              }}
            >
              Parents
            </label>
            {formData.parents.map((parent, index) => (
              <div key={index} style={{ marginBottom: '10px' }}>
                <input
                  type="text"
                  placeholder="Name"
                  value={parent.name}
                  onChange={(e) => handleNestedChange(e, index, 'name')}
                  style={{
                    display: 'inline-block',
                    marginRight: '10px',
                    marginBottom:'10px',
                    width: 'calc(100% - 120px)',
                    padding: '10px',
                    border: `1px solid ${formErrors[`parentName${index}`] ? 'red' : '#ddd'}`,
                    borderRadius: '6px',
                    boxSizing: 'border-box',
                    fontSize: '16px',
                  }}
                />
                {formErrors[`parentName${index}`] && (
                  <p
                    style={{
                      color: 'red',
                      fontSize: '0.875em',
                      marginTop: '5px',
                    }}
                  >
                    {formErrors[`parentName${index}`]}
                  </p>
                )}
                <input
                  type="text"
                  placeholder="Relationship"
                  value={parent.relationship}
                  onChange={(e) => handleNestedChange(e, index, 'relationship')}
                  style={{
                    display: 'inline-block',
                    marginRight: '10px',
                    marginBottom:'10px',
                    width: 'calc(100% - 120px)',
                    padding: '10px',
                    border: `1px solid ${formErrors[`relationship${index}`] ? 'red' : '#ddd'}`,
                    borderRadius: '6px',
                    boxSizing: 'border-box',
                    fontSize: '16px',
                  }}
                />
                {formErrors[`relationship${index}`] && (
                  <p
                    style={{
                      color: 'red',
                      fontSize: '0.875em',
                      marginTop: '5px',
                    }}
                  >
                    {formErrors[`relationship${index}`]}
                  </p>
                )}
                <input
                  type="text"
                  placeholder="Address"
                  value={parent.address}
                  onChange={(e) => handleNestedChange(e, index, 'address')}
                  style={{
                    display: 'inline-block',
                    marginRight: '10px',
                    marginBottom:'10px',
                    width: 'calc(100% - 120px)',
                    padding: '10px',
                    border: `1px solid ${formErrors[`address${index}`] ? 'red' : '#ddd'}`,
                    borderRadius: '6px',
                    boxSizing: 'border-box',
                    fontSize: '16px',
                  }}
                />
                {formErrors[`address${index}`] && (
                  <p
                    style={{
                      color: 'red',
                      fontSize: '0.875em',
                      marginTop: '5px',
                    }}
                  >
                    {formErrors[`address${index}`]}
                  </p>
                )}
                <input
                  type="text"
                  placeholder="Mobile Number"
                  value={parent.mobileNumber}
                  onChange={(e) => handleNestedChange(e, index, 'mobileNumber')}
                  style={{
                    display: 'inline-block',
                    marginRight: '10px',
                    marginBottom:'10px',
                    width: 'calc(100% - 120px)',
                    padding: '10px',
                    border: `1px solid ${formErrors[`mobileNumber${index}`] ? 'red' : '#ddd'}`,
                    borderRadius: '6px',
                    boxSizing: 'border-box',
                    fontSize: '16px',
                  }}
                />
                {formErrors[`mobileNumber${index}`] && (
                  <p
                    style={{
                      color: 'red',
                      fontSize: '0.875em',
                      marginTop: '5px',
                    }}
                  >
                    {formErrors[`mobileNumber${index}`]}
                  </p>
                )}
                <button
                  type="button"
                  onClick={() => removeParent(index)}
                  style={{
                    marginTop: '10px',
                    backgroundColor: '#dc3545',
                    color: 'white',
                    border: 'none',
                    padding: '12px 24px',
                    borderRadius: '6px',
                    cursor: 'pointer',
                    fontSize: '16px',
                    transition:
                      'background-color 0.3s ease, transform 0.2s ease',
                  }}
                >
                  Remove
                </button>
              </div>
            ))}
            <button
              type="button"
              onClick={addParent}
              style={{
                backgroundColor: '#007bff',
                color: 'white',
                border: 'none',
                padding: '12px 24px',
                borderRadius: '6px',
                cursor: 'pointer',
              }}
            >
              Add Parent
            </button>
          </div>
          <div style={{ marginBottom: '15px' }}>
            <button
              type="reset"
              style={{
                backgroundColor: '#f44336',
                color: 'white',
                border: 'none',
                padding: '12px 24px',
                borderRadius: '6px',
                cursor: 'pointer',
                fontSize: '16px',
                marginRight: '10px',
                transition: 'background-color 0.3s ease, transform 0.2s ease',
              }}
            >
              Reset
            </button>
            <button
              type="submit"
              disabled={isSubmitting}
              style={{
                backgroundColor: isSubmitting ? '#ccc' : '#4caf50',
                color: 'white',
                border: 'none',
                padding: '12px 24px',
                borderRadius: '6px',
                cursor: 'pointer',
                fontSize: '16px',
                transition: 'background-color 0.3s ease, transform 0.2s ease',
              }}
            >
              {isSubmitting ? 'Submitting...' : 'Submit'}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddParentsDetails;
